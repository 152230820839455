import medical_cases from '../../api/medical_cases'

const success = {
    CREATE_MEDICAL_CASE_SUCCESS: {
        title: "Succès",
        content: "Cas créé avec succès"
    },
    UPDATE_MEDICAL_CASE_SUCCESS: {
        title: "Succès",
        content: "Cas mis à jour"
    },
    CLOSE_MEDICAL_CASE_SUCCESS: {
        title: "Succès",
        content: "Cas fermé avec succès"
    },
}
const errors = {
    GET_MEDICAL_CASES_LOADING_ERROR: 'Erreur lors du chargement des cas, merci de réessayer',
    GET_MEDICAL_CASE_LOADING_ERROR: 'Erreur lors du chargement du cas, merci de réessayer',
    CREATE_MEDICAL_CASE_ERROR: 'Erreur lors de la création du cas, merci de réessayer',
    UPDATE_MEDICAL_CASE_ERROR: 'Erreur lors de la mise à jour du cas, merci de réessayer',
    CLOSE_MEDICAL_CASE_ERROR: 'Erreur lors de la fermeture du cas, merci de réessayer',
}

// initial state
const state = {
    open: [],
    history: [],
    selectedMedicalCase: {
        id: null,
        medical_info: {
            medical_histories: null
        },
        diagnostic: {
            type: null,
            description: null
        },
        treatments: [

        ]
    },
    form: {
        height: null,
        weight: null,
        articulation: null,
        smoking_quantity: null,
        diabetes: null,
        hypertension: null,
        cholesterol: null,
        arthritis: null,
        fibrillation: null,
        coagulation_disorder: null,
        none: null,
        medical_histories: [],
        pain_since: null,
        pain_effort: null,
        pain_rest: null,
        pain_night: null,
        accident_date: null,
        blocking_since: null,
        instability_since: null,
        inflated_since: null,
        inflated_count: null,
    }
}

// getters
const getters = {}

// actions
const actions = {
    getMedicalCaseInfo({state, commit, rootState}) {
        return new Promise((resolve, reject) => {
            medical_cases.get(rootState.patients.selectedPatient.id, state.selectedMedicalCase.id, rootState.axios).then(result => {
                commit('setSelectedMedicalCase', result.data.medical_case)
                resolve(result)
            }).catch(reason => {
                commit('notifications/showError', {
                    title: errors.GET_MEDICAL_CASE_LOADING_ERROR, content: reason
                }, {root: true})
                reject(reason)

            })
        })
    },
    getMedicalCases({state, commit, rootState}) {
        medical_cases.all(rootState.patients.selectedPatient.id, rootState.axios).then(result => {
            commit('setMedicalCases', result.data.medical_cases)
        }).catch(reason => {
            commit('notifications/showError', {
                title: errors.GET_MEDICAL_CASES_LOADING_ERROR, content: reason
            }, {root: true})

        })
    },
    createMedicalCase({state, commit, rootState}, payload) {
        return new Promise((resolve, reject) => {
            medical_cases.add(rootState.patients.selectedPatient.id, payload, rootState.axios).then(result => {
                commit('notifications/showSuccess', {
                    title: success.CREATE_MEDICAL_CASE_SUCCESS.title,
                    content: success.CREATE_MEDICAL_CASE_SUCCESS.content
                }, {root: true})
                resolve(result)
            }).catch(reason => {
                commit('notifications/showError', {
                    title: errors.CREATE_MEDICAL_CASE_ERROR, content: reason
                }, {root: true})
                reject(reason)
            })
        })
    },
    updateMedicalForm({state, commit, rootState}) {
        return new Promise((resolve, reject) => {
            medical_cases.update(rootState.patients.selectedPatient.id, state.selectedMedicalCase.id, state.form, rootState.axios).then(result => {
                commit('notifications/showSuccess', {
                    title: success.UPDATE_MEDICAL_CASE_SUCCESS.title,
                    content: success.UPDATE_MEDICAL_CASE_SUCCESS.content
                }, {root: true})
                resolve(result)
            }).catch(reason => {
                commit('notifications/showError', {
                    title: errors.UPDATE_MEDICAL_CASE_ERROR, content: reason
                }, {root: true})
                reject(reason)
            })
        })
    },
    closeMedicalCase({state, commit, rootState}, payload) {
        return new Promise((resolve, reject) => {
            medical_cases.close(rootState.patients.selectedPatient.id, state.selectedMedicalCase.id, payload, rootState.axios).then(result => {
                commit('notifications/showSuccess', {
                    title: success.CLOSE_MEDICAL_CASE_SUCCESS.title,
                    content: success.CLOSE_MEDICAL_CASE_SUCCESS.content
                }, {root: true})
                resolve(result)
            }).catch(reason => {
                commit('notifications/showError', {
                    title: errors.CLOSE_MEDICAL_CASE_ERROR, content: reason
                }, {root: true})
                reject(reason)
            })
        })
    }
}

// mutations
const mutations = {
    setMedicalCases(state, value) {
        state.open = value.open
        state.history = value.history
    },
    setFormHeight(state, value) {
        state.form.height = value
    },
    setFormWeight(state, value) {
        state.form.weight = value
    },
    setFormArticulation(state, value) {
        state.form.articulation = value
    },
    setFormSmokingQuantity(state, value) {
        state.form.smoking_quantity = value
    },
    setFormDiabetes(state, value) {
        state.form.diabetes = value
    },
    setFormHypertension(state, value) {
        state.form.hypertension = value
    },
    setFormCholesterol(state, value) {
        state.form.cholesterol = value
    },
    setFormArthritis(state, value) {
        state.form.arthritis = value
    },
    setFormFibrillation(state, value) {
        state.form.fibrillation = value
    },
    setFormCoagulationDisorder(state, value) {
        state.form.coagulation_disorder = value
    },
    setFormNone(state, value) {
        state.form.none = value
    },
    setFormName(state, value) {
        state.form.name = value
    },
    setSelectedMedicalCaseId(state, value) {
        state.selectedMedicalCase.id = value
    },
    setSelectedMedicalCase(state, value) {
        state.selectedMedicalCase = value
        if (value.medical_info) {
            state.form = {...value.medical_info}
        } else {
            state.form = {
                height: null,
                weight: null,
                articulation: null,
                smoking_quantity: null,
                diabetes: null,
                hypertension: null,
                cholesterol: null,
                arthritis: null,
                fibrillation: null,
                coagulation_disorder: null,
                medical_histories: [],
                pain_since: null,
                pain_effort: null,
                pain_rest: null,
                pain_night: null,
                accident_date: null,
                blocking_since: null,
                instability_since: null,
                inflated_since: null,
                inflated_count: null,
            }
        }

    },
    setPreviousSurgeryType(state, {index, data}) {
        state.form.medical_histories[index].type = data
    },
    setPreviousSurgeryDate(state, {index, data}) {
        state.form.medical_histories[index].date = data
    },
    removeMedicalHistory(state, indexToRemove) {
        state.form.medical_histories.splice(indexToRemove, 1)
    },
    addMedicalHistory(state) {
        state.form.medical_histories.push({type: null, date: null})
    },
    setFormPainSince(state, value) {
        state.form.pain_since = value
    },
    setFormPainEffort(state, value) {
        state.form.pain_effort = value
    },
    setFormPainRest(state, value) {
        state.form.pain_rest = value
    },
    setFormPainNight(state, value) {
        state.form.pain_night = value
    },
    setFormAccidentDate(state, value) {
        state.form.accident_date = value
    },
    setFormBlockingSince(state, value) {
        state.form.blocking_since = value
    },
    setFormInstabilitySince(state, value) {
        state.form.instability_since = value
    },
    setFormInflatedSince(state, value) {
        state.form.inflated_since = value
    },
    setFormInflatedCount(state, value) {
        state.form.inflated_count = value
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
