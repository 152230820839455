export default {
    get(id, caseId, axios) {
        return axios({
            url: `patients/${id}/medicalCase/${caseId}`,
            method: 'GET'
        })
    },
    all(id, axios) {
        return axios({
            url: `patients/${id}/medicalCases`,
            method: 'GET'
        })
    },
    add(id, caseData, axios) {
        return axios({
            url: `patients/${id}/addMedicalCase`,
            method: 'POST',
            data: caseData
        })
    },
    update(id, caseId, caseData, axios) {
        return axios({
            url: `patients/${id}/medicalCase/${caseId}/updateInfos`,
            method: 'POST',
            data: caseData
        })
    },
    close(id, caseId, closeData, axios) {
        return axios({
            url: `patients/${id}/medicalCase/${caseId}/closeCase`,
            method: 'POST',
            data: closeData
        })
    },
}
