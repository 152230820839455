<template>
    <v-card class="fill-height">
        <v-card-title primary-title>
            <h3 class="headline">Patients</h3>
            <v-spacer></v-spacer>
            <v-btn right color="primary" :to="{ name: 'patient.add' }">
                <v-icon>add</v-icon> Créer un patient
            </v-btn>


        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
            <v-layout align-center justify-end row>
                <v-flex xs6>
                    <v-text-field v-model="search" append-icon="search" label="Recherche" single-line
                                  hide-details></v-text-field>
                </v-flex>
            </v-layout>
        </v-card-text>

        <v-data-table
            class="mx-4"
            :headers="headers"
            :items="patients"
            :search="search"
            :pagination.sync="paginator">
            <template v-slot:items="props">
                <router-link tag="tr" :to="{name: 'patient.view', params: { id: props.item.id } }">
                    <td>{{ props.item.lastname }}</td>
                    <td>{{ props.item.firstname }}</td>
                    <td>{{ props.item.email }}</td>
                    <td>{{ props.item.last_updated }}</td>
                </router-link>
            </template>
            <template v-slot:no-results>
                <v-alert :value="true" color="error" icon="warning">
                    Aucun résultat pour "{{ search }}".
                </v-alert>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
    import {mapActions, mapState} from 'vuex'

    export default {
        name: "UserList",
        computed: mapState({
            patients: state => state.patients.all
        }),
        data() {
            return {
                has_error: false,
                search: '',
                headers: [
                    {text: 'Nom', align: 'left', value: 'lastname'},
                    {text: 'Prénom', value: 'firstname'},
                    {text: 'Email', value: 'email'},
                    {text: 'Date', value: 'last_updated'},
                ],
                paginatorText: "Résultats par page",
                paginator: {
                    rowsPerPage: 25,
                    sortBy: 'last_updated',
                    descending:true
                }
            }
        },

        mounted() {
            this.getAllPatients()
        },
        methods: {
            ...mapActions('patients', [
                'getAllPatients'
            ])
        }
    }
</script>
