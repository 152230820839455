<template>
    <v-layout row fill-height align-center justify-center>
        <v-flex md12 lg10>
            <v-card v-if="selectedFollowUp.question.complete === false" class="height-max">
                <v-progress-linear v-model="percentProgress" class="my-0"></v-progress-linear>
                <v-container
                    fluid
                    grid-list-xs
                >
                    <v-layout row>
                        <v-flex xs12>
                            <div v-if="selectedFollowUp.question.score_instructions">
                                <h3 v-html="selectedFollowUp.question.score_instructions" class="font-weight-regular"></h3>
                                <v-divider class="my-4"></v-divider>
                            </div>
                            <div v-if="selectedFollowUp.question.score_group_name">
                                <h3>{{ selectedFollowUp.question.score_group_name}}</h3>
                                <p v-html="selectedFollowUp.question.score_group_instructions"></p>
                            </div>
                        </v-flex>

                    </v-layout>

                    <v-layout align-center justify-center row v-if="!selectedFollowUp.question.special_type">
                        <v-flex xs12 sm8 offset-sm2>
                            <h2 class="my-4 question-title" v-html="selectedFollowUp.question.score_question_text"></h2>
                            <div class="custom-radio">
                                <div class="radio mb-2"
                                     v-for="answer in selectedFollowUp.question.score_question_answer"
                                     :key="answer.id">
                                    <input type="radio" name="answer" :value="answer.id" v-model="response"
                                           @change="sendAnswer()"
                                           :id="'answer'+answer.id">
                                    <label class="v-label theme--light" :for="'answer'+answer.id">{{answer.text}}<span
                                        v-if="answer.remarks" v-html="'<br>' + answer.remarks"></span></label>
                                </div>
                            </div>
                        </v-flex>
                        <v-spacer></v-spacer>
                    </v-layout>

                    <v-layout align-center justify-center row v-else-if="selectedFollowUp.question.special_type == 'scale'">
                        <v-flex xs12 sm10 offset-sm1>
                            <h2 class="my-4 question-title" v-html="selectedFollowUp.question.score_question_text"></h2>

                            <v-layout>
                                <v-flex xs12>
                                    <v-slider
                                    :min="selectedFollowUp.question.score_question_answer[0].text"
                                    :max="selectedFollowUp.question.score_question_answer[selectedFollowUp.question.score_question_answer.length - 1].text"
                                    ticks
                                    always-dirty
                                    v-model="slider_value"
                                    :tick-labels="getTicksLabels()"
                                    @change="updateResponse()"
                                    tick-size="0"
                                    thumb-label="always"
                                    class="mb-4">
                                        <template v-slot:prepend>
                                            {{ selectedFollowUp.question.score_question_answer[0].remarks }}
                                        </template>
                                        <template v-slot:append>
                                            {{ selectedFollowUp.question.score_question_answer[selectedFollowUp.question.score_question_answer.length - 1].remarks }}
                                        </template>
                                    </v-slider>
                                </v-flex>

                            </v-layout>

                        </v-flex>
                        <v-spacer></v-spacer>
                    </v-layout>

                    <v-layout align-center justify-center row v-if="!selectedFollowUp.question.special_type">
                        <v-flex xs3 text-xs-left>
                            <v-btn text color="primary" @click="removeLast()">
                                Précédent
                            </v-btn>
                        </v-flex>
                        <v-flex xs3 offset-xs3 text-xs-right>
                            <v-btn v-if="response && false" text color="primary" @click="sendAnswer()">
                                Suivant
                            </v-btn>
                        </v-flex>
                    </v-layout>

                    <v-layout align-center justify-center row v-else>
                        <v-flex xs3 text-xs-left>
                            <v-btn text color="primary" @click="removeLast()">
                                Précédent
                            </v-btn>
                        </v-flex>
                        <v-flex xs3 offset-xs3 text-xs-right>
                            <v-btn text color="primary" @click="sendAnswer()">
                                Suivant
                            </v-btn>
                        </v-flex>
                    </v-layout>

                </v-container>
            </v-card>
            <v-card v-if="selectedFollowUp.question.complete" class="pa-4">
                <v-card-title>
                    <v-layout column mt-4>
                        <v-flex pa-2>
                            <h2>Le questionnaire est terminé.</h2>
                        </v-flex>
                        <v-flex px-2>
                            <span class="title">Nous vous remercions de votre participation.</span>
                        </v-flex>
                    </v-layout>
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="back">
                        Retour
                    </v-btn>
                </v-card-actions>
            </v-card>

        </v-flex>
    </v-layout>
</template>

<script>
    import {mapActions, mapState, mapMutations} from 'vuex'
    import {getTranslationString} from "../../../helper";

    export default {
        name: "ScoreView",
        data() {
            return {
                response: null,
                btn_next: false,
                percentProgress: 0,
                slider_value: 0
            }
        },
        created() {
            this.setSelectedPatientId(this.$route.params.patient_id)
            this.setSelectedMedicalCaseId(this.$route.params.medical_case_id)
            this.setSelectedTreatmentId(this.$route.params.treatment_id)
            this.setSelectedFollowUpId(this.$route.params.follow_up_id)
            this.getQuestion().then(response => {
                this.refreshProgress()
                if (response.data.follow_up.special_type === "scale") {
                    this.updateResponse()
                }
            }).catch(reason => {
                //Stay here..
            })
        },
        mounted() {
            if (this.$route.params.locked === true) {
                this.updateLockedState("true")
                this.setLockedBack(this.$route.params.back)
            }
        },
        computed: {
            ...mapState('patients', {
                selectedPatient: state => state.selectedPatient,
            }),
            ...mapState('medical_cases', {
                selectedMedicalCase: state => state.selectedMedicalCase,
            }),
            ...mapState('treatments', {
                selectedTreatment: state => state.selectedTreatment,
            }),
            ...mapState('follow_ups', {
                selectedFollowUp: state => state.selectedFollowUp,
            })

        },
        methods: {
            ...mapMutations(['updateLockedState', 'setLockedBack']),
            ...mapMutations('medical_cases', [
                'setSelectedMedicalCaseId'
            ]),
            ...mapMutations('treatments', [
                'setSelectedTreatmentId'
            ]),
            ...mapMutations('patients', [
                'setSelectedPatientId'
            ]),
            ...mapMutations('follow_ups', [
                'setSelectedFollowUpId'
            ]),
            ...mapActions('follow_ups', [
                'getQuestion', 'updateQuestion', 'removeLastAnswer'
            ]),
            getTicksLabels() {

                if (this.selectedFollowUp.question.score_question_answer != null) {
                    return this.selectedFollowUp.question.score_question_answer.map(answer => answer.text)
                }
                return []
            },
            updateResponse() {
                if (this.slider_value != null) {
                    this.response = this.selectedFollowUp.question.score_question_answer[this.slider_value].id
                }
            },
            sendAnswer() {
                this.$nextTick(function () {
                        setTimeout(() => {
                            if (this.response !== null) {
                                this.updateQuestion({score_question_answer_id: this.response}).then(response => {
                                    this.response = null
                                    this.btn_next = false
                                    this.refreshProgress()
                                    this.slider_value = 0
                                    if (response.data.follow_up.special_type === "scale") {
                                        this.updateResponse()
                                    }
                                }).catch(reason => {
                                    //Stay here..
                                })
                            }
                        }, 150);

                })

            },
            removeLast() {
                this.removeLastAnswer({score_question_answer_id: this.response}).then(response => {
                    this.response = null
                    this.btn_next = false
                    this.refreshProgress()
                    this.slider_value = 0
                    if (response.data.follow_up.special_type === "scale") {
                        this.updateResponse()
                    }
                }).catch(reason => {
                    //Stay here..
                })

            },
            refreshProgress() {
                if (this.selectedFollowUp.question.progression > 0) {
                    this.percentProgress = (this.selectedFollowUp.question.progression / this.selectedFollowUp.question.total_questions) * 100
                }
            },
            back() {
                //this.$router.back()
                this.$router.replace({
                    name: 'patient.viewTreatment',
                    params: {
                        id: this.selectedPatient.id,
                        medical_case_id: this.selectedMedicalCase.id,
                        treatment_id: this.selectedTreatment.id}
                })
            },
            formatAnswer(text, remark) {
                if (remark !== null) {
                    return text + " - " + remark
                }
                return text
            }
        }
    }
</script>

<style scoped>
    .height-max {
        height: 100%;
        overflow: hidden;
    }


</style>
