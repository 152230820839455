import follow_ups from '../../api/follow_ups'

const success = {
    CREATE_FOLLOWUP_SUCCESS: {
        title: "Succès",
        content: "Suivi créé avec succès"
    },
    UPDATE_FOLLOWUP_SUCCESS: {
        title: "Succès",
        content: "Suivi mis à jour"
    },
}
const errors = {
    CREATE_FOLLOWUP_ERROR: 'Erreur lors de la création du suivi, merci de réessayer',
    UPDATE_FOLLOWUP_ERROR: 'Erreur lors de la mise à jour du suivi, merci de réessayer',
    GET_FOLLOWUP_LOADING_ERROR: 'Erreur lors du chargement du suivi, merci de réessayer',
}

// initial state
const state = {
    selectedFollowUp: {
        id: null,
        question: {
            completed: false,
            score_group_instructions: null,
            score_group_name: null,
            score_instructions: null,
            score_question_answer: null,
            score_question_text: null,
            progression: 0,
            total_questions: 0,
        }
    },
    resultFollowUp: {
        follow_up: {
            follow_up_definition: {
                name: ''
            },
            follow_up_scores: null,
            treatment: {
                medical_case: {
                    diagnostic: {
                        type: null
                    }
                },
                type: null
            }
        }
    }

    /*selectedTreatment: {
        id: null,
        type: null,
        description: null,
        medical_case: {
            diagnostic: {
                type: null
            }
        }
    },
    showForm: false*/
}

// getters
const getters = {}

// actions
const actions = {

    createFollowUp({state, commit, rootState}, payload) {
        return new Promise((resolve, reject) => {
            follow_ups.createFollowUp(rootState.patients.selectedPatient.id, rootState.medical_cases.selectedMedicalCase.id, rootState.treatments.selectedTreatment.id, payload, rootState.axios).then(result => {
                /*                commit('notifications/showSuccess', {
                                    title: success.CREATE_FOLLOWUP_SUCCESS.title, content: success.CREATE_FOLLOWUP_SUCCESS.content
                                }, {root: true})*/
                resolve(result)
            }).catch(reason => {
                commit('notifications/showError', {
                    title: errors.CREATE_FOLLOWUP_ERROR, content: reason
                }, {root: true})
                reject(reason)
            })
        })
    },
    getQuestion({state, commit, rootState}) {
        return new Promise((resolve, reject) => {
            follow_ups.next(rootState.patients.selectedPatient.id, rootState.medical_cases.selectedMedicalCase.id, rootState.treatments.selectedTreatment.id, rootState.follow_ups.selectedFollowUp.id, rootState.axios).then(result => {
                commit('setQuestion', result.data.follow_up)
                resolve(result)
            }).catch(reason => {
                commit('notifications/showError', {
                    title: errors.GET_FOLLOWUP_LOADING_ERROR, content: reason
                }, {root: true})
                reject(reason)
            })
        })
    },
    removeLastAnswer({state, commit, rootState}) {
        return new Promise((resolve, reject) => {
            follow_ups.removeLast(rootState.patients.selectedPatient.id, rootState.medical_cases.selectedMedicalCase.id, rootState.treatments.selectedTreatment.id, rootState.follow_ups.selectedFollowUp.id, rootState.axios).then(result => {
                commit('setQuestion', result.data.follow_up)
                resolve(result)
            }).catch(reason => {
                commit('notifications/showError', {
                    title: errors.GET_FOLLOWUP_LOADING_ERROR, content: reason
                }, {root: true})
                reject(reason)
            })
        })
    },
    updateQuestion({state, commit, rootState}, payload) {
        return new Promise((resolve, reject) => {
            follow_ups.update(rootState.patients.selectedPatient.id, rootState.medical_cases.selectedMedicalCase.id, rootState.treatments.selectedTreatment.id, rootState.follow_ups.selectedFollowUp.id, payload, rootState.axios).then(result => {
                if (!result.data.follow_up.completed) {
                    commit('setQuestion', result.data.follow_up)
                } else {

                }
                commit('notifications/showSuccess', {
                    title: success.UPDATE_FOLLOWUP_SUCCESS.title, content: success.UPDATE_FOLLOWUP_SUCCESS.content
                }, {root: true})
                resolve(result)
            }).catch(reason => {
                commit('notifications/showError', {
                    title: errors.UPDATE_FOLLOWUP_ERROR, content: reason
                }, {root: true})
                reject(reason)
            })
        })
    },
    viewResult({state, commit, rootState}) {
        return new Promise((resolve, reject) => {
            follow_ups.view(rootState.patients.selectedPatient.id, rootState.medical_cases.selectedMedicalCase.id, rootState.treatments.selectedTreatment.id, rootState.follow_ups.selectedFollowUp.id, rootState.axios).then(result => {
                commit('setFollowUp', result.data.results)
                resolve(result)
            }).catch(reason => {
                commit('notifications/showError', {
                    title: errors.GET_FOLLOWUP_LOADING_ERROR, content: reason
                }, {root: true})
                reject(reason)
            })
        })
    },
    exportResult({state, commit, rootState}) {
        return new Promise((resolve, reject) => {
            follow_ups.export(rootState.patients.selectedPatient.id, rootState.medical_cases.selectedMedicalCase.id, rootState.treatments.selectedTreatment.id, rootState.follow_ups.selectedFollowUp.id, rootState.axios).then(result => {
                resolve(result)
            }).catch(reason => {
                commit('notifications/showError', {
                    title: errors.GET_FOLLOWUP_LOADING_ERROR, content: reason
                }, {root: true})
                reject(reason)
            })
        })
    },
    exportAllResults({state, commit, rootState}) {
        return new Promise((resolve, reject) => {
            follow_ups.fullExport(rootState.axios).then(result => {
                resolve(result)
            }).catch(reason => {
                commit('notifications/showError', {
                    title: errors.GET_FOLLOWUP_LOADING_ERROR, content: reason
                }, {root: true})
                reject(reason)
            })
        })
    },
    /*getTreatmentInfo({state, commit, rootState}) {
        return new Promise((resolve, reject) => {
            treatments.get(rootState.patients.selectedPatient.id, rootState.medical_cases.selectedMedicalCase.id, rootState.treatments.selectedTreatment.id, rootState.axios).then(result => {
                commit('setSelectedTreatment', result.data.treatment)
                resolve(result)
            }).catch(reason => {
                commit('notifications/showError', {
                    title: errors.GET_TREATMENT_LOADING_ERROR, content: reason
                }, {root: true})
                reject(reason)
            })
        })
    },*/
}

// mutations
const mutations = {
    setQuestion(state, value) {
        state.selectedFollowUp.question = value
    },
    setSelectedFollowUpId(state, value) {
        state.selectedFollowUp.id = value
    },
    setCompleted(state, value) {
        state.selectedFollowUp.question.completed = value
    },
    setFollowUp(state, value) {
        state.resultFollowUp = value
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
