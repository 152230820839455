<template>
    <v-layout column>
        <navigation>
            <template v-slot:title>
                HLT Suivi Patient
            </template>
        </navigation>
        <router-view></router-view>
    </v-layout>
</template>

<script>
    import Navigation from '../../components/Navigation'
    export default {
        name: "Home",
        components: {
            'navigation' : Navigation
        }
    }
</script>

<style scoped>

</style>
