<template>
    <v-container grid-list-sm pa-0>
        <v-layout row wrap>
            <v-flex md5 lg4>
                <v-card tile flat class="fill-height border-right">
                    <v-card-text pa-5>
                        <v-container grid-list-sm pa-0>
                            <v-layout>
                                <v-flex>
                                    <h2>Cas : {{ selectedMedicalCase.name }}</h2>
                                    <p>{{ selectedMedicalCase.created_at | shortDate }}</p>
                                </v-flex>
                                <v-spacer></v-spacer>
                                <v-btn flat v-if="selectedMedicalCase.medical_info && selectedMedicalCase.open"
                                       @click="edit_case = true">
                                    <v-icon>edit</v-icon>
                                </v-btn>
                                <v-dialog v-model="edit_case" persistent max-width="480" v-if="selectedMedicalCase.open">
                                    <v-card>
                                        <v-card-title class="headline">Modification du cas</v-card-title>
                                        <v-card-text>Voulez-vous vérouiller la tablette et activer la saisie patient ?
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="secondary" text @click="editCase(false)">Non, continuer
                                            </v-btn>
                                            <v-btn color="primary" text @click="editCase(true)">Oui, verrouiller</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-layout>
                            <v-layout row wrap>
                                <v-flex md6>
                                    <div v-if="selectedMedicalCase.medical_info">

                                        <p><span class="subheading font-weight-bold">Articulation :</span><br>{{
                                            this.getText(selectedMedicalCase.medical_info.articulation)
                                            }}</p>
                                        <p v-if="selectedMedicalCase.medical_info.accident_date"><span
                                            class="subheading font-weight-bold">Date de l'accident :</span><br>
                                            {{ selectedMedicalCase.medical_info.accident_date | shortDate }}</p>
                                        <p><span class="subheading font-weight-bold">Douleurs :</span><br>{{
                                            selectedMedicalCase.medical_info.pain_since ? "Oui" :
                                            "Non"
                                            }}<span v-if="selectedMedicalCase.medical_info.pain_since">, {{ this.getText(selectedMedicalCase.medical_info.pain_since) }}</span>
                                        </p>
                                        <p v-if="selectedMedicalCase.medical_info.pain_since">
                                            <span class="subheading font-weight-bold">Douleurs à l’effort :</span><br>{{
                                            selectedMedicalCase.medical_info.pain_effort
                                            ?
                                            "Oui" : "Non" }}<br>
                                            <span class="subheading font-weight-bold">Douleurs au repos :</span><br>{{
                                            selectedMedicalCase.medical_info.pain_rest
                                            ?
                                            "Oui"
                                            : "Non" }}<br>
                                            <span class="subheading font-weight-bold">Douleurs la nuit :</span><br>{{
                                            selectedMedicalCase.medical_info.pain_night
                                            ?
                                            "Oui"
                                            : "Non" }}
                                        </p>
                                        <p><span class="subheading font-weight-bold">Blocages :</span><br>{{
                                            selectedMedicalCase.medical_info.blocking_since ? "Oui"
                                            :
                                            "Non"
                                            }}<span v-if="selectedMedicalCase.medical_info.blocking_since">, {{ this.getText(selectedMedicalCase.medical_info.blocking_since) }}</span>
                                        </p>
                                        <p>
                                            <span class="subheading font-weight-bold">Instabilité :</span><br>{{
                                            selectedMedicalCase.medical_info.instability_since ?
                                            "Oui" :
                                            "Non" }}<span v-if="selectedMedicalCase.medical_info.instability_since">, {{ this.getText(selectedMedicalCase.medical_info.instability_since) }}</span>
                                        </p>
                                        <p>
                                            <span class="subheading font-weight-bold">Gonflements :</span><br>{{
                                            selectedMedicalCase.medical_info.inflated_since ?
                                            "Oui" :
                                            "Non" }}<span v-if="selectedMedicalCase.medical_info.inflated_since">, {{ this.getText(selectedMedicalCase.medical_info.inflated_since) }}</span>
                                            <span v-if="selectedMedicalCase.medical_info.inflated_since"><br>
                                    {{ selectedMedicalCase.medical_info.inflated_count == 1 ? 'Une seule fois' :
                                    'Plusieurs fois' }}<br>
                                </span>
                                        </p>
                                    </div>
                                    <v-layout v-else-if="selectedMedicalCase.open">
                                        <v-btn color="secondary"
                                               @click="edit_case = true"
                                               >
                                            <v-icon>add</v-icon>
                                            Ajouter infos patient
                                        </v-btn>
                                    </v-layout>
                                </v-flex>
                                <v-flex md6>
                                    <div v-if="selectedMedicalCase.medical_info">
                                        <p v-if="selectedMedicalCase.medical_info.weight && selectedMedicalCase.medical_info.height">
                                            <span class="subheading font-weight-bold">IMC :</span><br>{{
                                            (selectedMedicalCase.medical_info.weight /
                                            ((selectedMedicalCase.medical_info.height / 100) *
                                            (selectedMedicalCase.medical_info.height / 100))).toFixed(1) }}
                                        </p>
                                        <p>
                                            <span class="subheading font-weight-bold">Taille :</span><br>
                                            {{ selectedMedicalCase.medical_info.height ? selectedMedicalCase.medical_info.height : "-" }} cm
                                        </p>
                                        <p>
                                            <span class="subheading font-weight-bold">Poids :</span><br>{{
                                            selectedMedicalCase.medical_info.weight ? selectedMedicalCase.medical_info.weight : "-" }} kg
                                        </p>
                                        <p>
                                            <span class="subheading font-weight-bold">Fumeur :</span><br>{{
                                            selectedMedicalCase.medical_info.smoking_quantity ? ("Oui"
                                            + " ("
                                            + selectedMedicalCase.medical_info.smoking_quantity + "/jour)") : "Non" }}
                                        </p>

                                        <p v-if="selectedPatient.job">
                                            <span class="subheading font-weight-bold">Travail :</span><br>
                                            {{ this.getText(selectedPatient.job) }}
                                        </p>

                                        <p v-if="selectedPatient.tegner">
                                            <span class="subheading font-weight-bold">Score Tegner (sport) :</span><br>
                                            {{ selectedPatient.tegner }}
                                        </p>

                                        <span class="subheading font-weight-bold">Maladies connues :</span>
                                        <p v-if="selectedMedicalCase.medical_info.diabetes
                                            || selectedMedicalCase.medical_info.hypertension
                                            || selectedMedicalCase.medical_info.cholesterol
                                            || selectedMedicalCase.medical_info.arthritis
                                            || selectedMedicalCase.medical_info.fibrillation
                                            || selectedMedicalCase.medical_info.coagulation_disorder">
                                        <ul>
                                            <li v-if="selectedMedicalCase.medical_info.diabetes">{{
                                                this.getText("DISEASE_DIABETES") }}
                                            </li>
                                            <li v-if="selectedMedicalCase.medical_info.hypertension">{{
                                                this.getText("DISEASE_HYPERTENSION") }}
                                            </li>
                                            <li v-if="selectedMedicalCase.medical_info.cholesterol">{{
                                                this.getText("DISEASE_CHOLESTEROL") }}
                                            </li>
                                            <li v-if="selectedMedicalCase.medical_info.arthritis">{{
                                                this.getText("DISEASE_ARTHRITIS") }}
                                            </li>
                                            <li v-if="selectedMedicalCase.medical_info.fibrillation">{{
                                                this.getText("DISEASE_FIBRILLATION") }}
                                            </li>
                                            <li v-if="selectedMedicalCase.medical_info.coagulation_disorder">{{
                                                this.getText("DISEASE_COAGULATION_DISORDER") }}
                                            </li>
                                        </ul>
                                        </p>
                                        <p v-else>Aucune</p>
                                        <span class="subheading font-weight-bold">Opérations précédentes :</span>
                                        <div
                                            v-if="selectedMedicalCase.medical_info.medical_histories && selectedMedicalCase.medical_info.medical_histories.length">
                                            <ul>
                                                <li v-for="surgery in selectedMedicalCase.medical_info.medical_histories"
                                                    :key="'surgery_' + surgery.id">
                                                    {{ surgery.type }} - {{ surgery.date | shortDate }}
                                                </li>
                                            </ul>
                                        </div>
                                        <p v-else>Aucune opération connue</p>

                                    </div>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-flex>

            <v-flex md7 lg8>
                <v-card tile flat>
                    <v-card-text pa-5>
                        <v-container grid-list-sm pa-0>
                            <v-layout row>
                                <v-flex sm12>

                                    <v-layout>
                                        <h2 class="mb-0">Diagnostic</h2>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            v-if="selectedMedicalCase.open && !selectedMedicalCase.diagnostic"
                                            color="secondary"
                                            @click="diagnosticModal(null)">
                                            <v-icon left>add</v-icon>
                                            Nouveau diagnostic
                                        </v-btn>
                                        <v-btn v-else-if="selectedMedicalCase.open && selectedMedicalCase.diagnostic" flat
                                               @click="diagnosticModal(selectedMedicalCase.diagnostic)">
                                            <v-icon>edit</v-icon>
                                        </v-btn>
                                    </v-layout>
                                </v-flex>
                            </v-layout>

                            <v-layout row v-if="selectedMedicalCase.diagnostic">
                                <v-flex sm4>
                                    {{ getText(selectedMedicalCase.diagnostic.type) }}
                                    <br>{{ selectedMedicalCase.diagnostic.created_at | shortDate }}
                                </v-flex>
                                <v-flex sm8 v-html="selectedMedicalCase.diagnostic.description">
                                </v-flex>
                            </v-layout>
                            <v-divider class="my-3"></v-divider>
                            <v-layout row>
                                <v-flex sm12>
                                    <v-layout>
                                        <h2 class="mb-0">Traitements</h2>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            v-if="selectedMedicalCase.diagnostic && selectedMedicalCase.open"
                                            color="secondary" @click="treatmentModal(!showTreatmentForm)">
                                            <v-icon>add</v-icon>
                                            Nouveau traitement
                                        </v-btn>
                                    </v-layout>
                                    <v-list class="mt-3 treatment" subheader>
                                        <v-subheader>Traitement ouvert</v-subheader>
                                        <template v-for="(treatment, index) in selectedMedicalCase.treatments">
                                            <v-list-tile class="full-height" @click="goToTreatment(treatment.id)"
                                                         :key="'treatment_' + treatment.id">
                                                <v-list-tile-content>
                                                    <v-container grid-list-sm px-0 py-3 fill-height>
                                                        <v-layout row align-center>
                                                            <v-flex sm4>
                                                                <span class="subheading font-weight-bold">{{ getText(treatment.type) }}</span><br>
                                                                {{ treatment.created_at | shortDate }}
                                                            </v-flex>
                                                            <!--<v-flex sm6>
                                                                {{ treatment.description }}
                                                            </v-flex>-->
                                                            <v-flex class="sm8 text-xs-right">
                                                        <span v-for="followup in treatment.follow_ups"
                                                              :key="'follow_up_' + followup.id">
                                                            <v-icon v-if="followup.completed"
                                                                    color="green darken-1"
                                                                    medium>mdi-calendar-check</v-icon>
                                                            <v-icon v-else-if="followup.skip"
                                                                    color="red darken-2"
                                                                    medium>mdi-calendar-remove</v-icon>
                                                            <v-icon v-else color="orange lighten-1" medium>mdi-calendar-alert</v-icon>
                                                        </span>
                                                                <span
                                                                    v-for="n in treatment.follow_ups_definition_count">
                                                            <v-icon color="grey darken-1"
                                                                    medium>mdi-calendar-clock</v-icon>
                                                        </span>
                                                            </v-flex>
                                                        </v-layout>
                                                    </v-container>
                                                </v-list-tile-content>
                                                <v-list-tile-action>
                                                    <v-btn flat
                                                           @click="goToTreatment(treatment.id)">
                                                        <v-icon color="primary" class="pl-4">arrow_forward</v-icon>
                                                    </v-btn>
                                                </v-list-tile-action>
                                            </v-list-tile>
                                            <v-divider v-if="index + 1 < selectedMedicalCase.treatments.length"></v-divider>
                                        </template>
                                        <v-divider></v-divider>
                                        <v-subheader>Traitement fermé</v-subheader>
                                        <template v-for="(treatment, index) in selectedMedicalCase.close_treatments">
                                            <v-list-tile class="full-height"  @click="goToTreatment(treatment.id)"
                                                         :key="'treatment_' + treatment.id">
                                                <v-list-tile-title>{{ getText(treatment.type) }}</v-list-tile-title>
                                                <v-list-tile-sub-title>ouvert le {{ treatment.created_at | shortDate }},
                                                    fermé le
                                                    {{ treatment.updated_at | shortDate }}
                                                </v-list-tile-sub-title>
                                                <v-list-tile-action>
                                                    <v-tooltip v-if="treatment.remarks" bottom>
                                                        <template v-slot:activator="{ on }">
                                                            <v-icon color="grey" dark v-on="on">info</v-icon>
                                                        </template>
                                                        <span>{{ treatment.remarks}}</span>
                                                    </v-tooltip>
                                                </v-list-tile-action>
                                                <v-list-tile-action>
                                                    <v-btn flat
                                                           @click="goToTreatment(treatment.id)">
                                                        <v-icon color="grey" class="pl-4">arrow_forward</v-icon>
                                                    </v-btn>
                                                </v-list-tile-action>
                                            </v-list-tile>
                                        </template>
                                    </v-list>
                                </v-flex>
                            </v-layout>
                            <v-dialog max-width="600px" persistent v-model="showDiagnosticModal">
                                <diagnostic-form></diagnostic-form>
                            </v-dialog>
                            <v-dialog max-width="600px" persistent v-model="showTreatmentForm">
                                <treatment-form></treatment-form>
                            </v-dialog>

                        </v-container>
                    </v-card-text>
                </v-card>

            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import {mapActions, mapState, mapMutations} from 'vuex'
    import PatientGeneralInfosCard from "../cards/PatientGeneralInfosCard";
    import DiagnosticForm from "../diagnostic/DiagnosticForm";
    import TreatmentForm from "../treatment/TreatmentForm";
    import {getTranslationString} from "../../../helper";

    export default {
        name: "MedicalCaseDetails",
        components: {
            'patient-general-infos-card': PatientGeneralInfosCard,
            'diagnostic-form': DiagnosticForm,
            'treatment-form': TreatmentForm
        },
        data: () => ({
            edit_case: false,
        }),
        created() {
            this.setSelectedMedicalCaseId(this.$route.params.medical_case_id)
            this.getMedicalCaseInfo()
        },
        computed: {
            ...mapState('patients', {
                selectedPatient: state => state.selectedPatient,
            }),
            ...mapState('medical_cases', {
                selectedMedicalCase: state => state.selectedMedicalCase,
            }),
            ...mapState('treatments', {
                showTreatmentForm: state => state.showForm
            }),
            ...mapState('diagnostics', {
                showDiagnosticModal: state => state.showModal
            })
        },
        methods: {
            ...mapActions('medical_cases', [
                'getMedicalCaseInfo'
            ]),
            ...mapMutations('medical_cases', [
                'setSelectedMedicalCaseId'
            ]),
            ...mapMutations('treatments', [
                'setShowForm', 'resetForm'
            ]),
            ...mapMutations('diagnostics', {
                initDiagnosticForm: 'initForm',
                toggleDiagnosticModal: 'setShowModal'
            }),
            getText(key) {
                return getTranslationString(key)
            },
            diagnosticModal(diagnostic) {
                this.initDiagnosticForm(diagnostic)
                this.toggleDiagnosticModal(true)
            },
            treatmentModal(show) {
                this.resetForm()
                this.setShowForm(show)
            },
            goToTreatment(treatmentId) {
                this.$router.push({
                    name: 'patient.viewTreatment',
                    params: {medical_case_id: this.selectedMedicalCase.id, treatment_id: treatmentId}
                })
            },
            getStatusColor(followup) {
                if (followup.completed) {
                    return "green--text darken-1"
                } else if (followup.skip) {
                    return "red--text darken-2"
                } else {
                    return "orange--text lighten-1"
                }
            },
            editCase(locked) {
                this.edit_case = false;
                this.$router.push({
                    name: 'patient.addMedicalCase', params: {
                        medical_case_id: this.selectedMedicalCase.id,
                        locked: locked,
                        back: this.$route.fullPath,
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>
