export default {
    add(id, caseId, diagnosticData, axios) {
        return axios({
            url: `patients/${id}/medicalCase/${caseId}/addDiagnostic`,
            method: 'POST',
            data: diagnosticData
        })
    },
    update(id, caseId, diagnosticId, diagnosticData, axios) {
        return axios({
            url: `patients/${id}/medicalCase/${caseId}/updateDiagnostic/${diagnosticId}`,
            method: 'POST',
            data: diagnosticData
        })
    },
}
