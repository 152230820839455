<template>
    <v-container grid-list-xl justify-space-between>
        <v-layout row wrap>
            <v-flex xs12>
                <v-card-title primary-title>
                    <v-container>
                        <span class="title primary--text">Cas: {{ name }}</span>

                        <v-stepper v-model="step">
                            <v-stepper-header>
                                <template v-for="stepIndex in finalStep -1">
                                    <v-stepper-step :step="stepIndex" @click="step = stepIndex"></v-stepper-step>
                                    <v-divider v-if="stepIndex < finalStep -1" ></v-divider>
                                </template>
                            </v-stepper-header>

                            <v-stepper-items>
                                <v-stepper-content step="1">
                                    <v-form ref="articulationForm" lazy-validation>
                                        <v-container grid-list-md>
                                            <v-layout>
                                                <v-flex md6 offset-md3>
                                                    <v-select
                                                        v-model="articulation"
                                                        :items="[
                                                                {text: getText('ARTICULATION_LEFT_KNEE'), value: 'ARTICULATION_LEFT_KNEE'},
                                                                {text: getText('ARTICULATION_RIGHT_KNEE'), value: 'ARTICULATION_RIGHT_KNEE'},
                                                                {text: getText('ARTICULATION_BOTH_KNEE'), value: 'ARTICULATION_BOTH_KNEE'},
                                                                {text: getText('ARTICULATION_LEFT_HIP'), value: 'ARTICULATION_LEFT_HIP'},
                                                                {text: getText('ARTICULATION_RIGHT_HIP'), value: 'ARTICULATION_RIGHT_HIP'},
                                                                {text: getText('ARTICULATION_UPPER_LIMB'), value: 'ARTICULATION_UPPER_LIMB'}
                                                            ]"
                                                        label="A quelle articulation avez-vous un problème ?"
                                                        required
                                                    ></v-select>
                                                </v-flex>
                                            </v-layout>
                                        </v-container>
                                    </v-form>

                                </v-stepper-content>
                                <v-stepper-content step="2">
                                    <v-form ref="bmiForm" lazy-validation>
                                        <v-container grid-list-md >
                                            <v-layout>
                                                <v-flex md4 offset-md3>
                                                    <v-text-field v-model="height" type="number" label="Quel est votre taille ?" suffix="cm" required></v-text-field>
                                                    <v-text-field v-model="weight" type="number" label="Quel est votre poids ?" suffix="kg" required></v-text-field>
                                                </v-flex>
                                            </v-layout>
                                        </v-container>
                                    </v-form>

                                </v-stepper-content>
                                <v-stepper-content step="3">
                                    <v-form ref="smokingForm" lazy-validation>
                                        <v-container grid-list-md>
                                            <v-layout>
                                                <v-flex md6 offset-md3>
                                                    <v-label>Est-ce que vous fumez?</v-label>
                                                    <v-radio-group v-model="isSmoker" row>
                                                        <v-radio label="Oui" :value="true"></v-radio>
                                                        <v-radio label="Non" :value="false"></v-radio>
                                                    </v-radio-group>
                                                    <v-text-field v-if="isSmoker" v-model="smoking_quantity" type="number" label="Combien de cigarettes par jour ?" required></v-text-field>
                                                </v-flex>
                                            </v-layout>
                                        </v-container>
                                    </v-form>

                                </v-stepper-content>
                                <v-stepper-content step="4">
                                    <v-form ref="diseasesForm">
                                        <v-container grid-list-md>
                                            <v-layout>
                                                <v-flex md8 offset-md2>
                                                    <v-label>Êtes-vous connu pour les maladies suivantes :</v-label>
                                                    <v-checkbox v-model="diabetes" :rules="diseasesRules" hide-details :label="getText('DISEASE_DIABETES')"></v-checkbox>
                                                    <v-checkbox v-model="hypertension" :rules="diseasesRules" hide-details :label="getText('DISEASE_HYPERTENSION')"></v-checkbox>
                                                    <v-checkbox v-model="cholesterol" :rules="diseasesRules" hide-details :label="getText('DISEASE_CHOLESTEROL')"></v-checkbox>
                                                    <v-checkbox v-model="arthritis" :rules="diseasesRules" hide-details :label="getText('DISEASE_ARTHRITIS')"></v-checkbox>
                                                    <v-checkbox v-model="fibrillation" :rules="diseasesRules" hide-details :label="getText('DISEASE_FIBRILLATION')"></v-checkbox>
                                                    <v-checkbox v-model="coagulation_disorder" :rules="diseasesRules" hide-details :label="getText('DISEASE_COAGULATION_DISORDER')"></v-checkbox>
                                                    <v-checkbox v-model="none" :rules="diseasesRules" :label="getText('DISEASE_NONE')"></v-checkbox>
                                                </v-flex>
                                            </v-layout>
                                        </v-container>
                                    </v-form>

                                </v-stepper-content>
                                <v-stepper-content step="5">
                                    <v-form ref="surgeryForm" lazy-validation  v-model="surgeryFormValid">
                                        <v-container grid-list-md>
                                            <v-layout>
                                                <v-flex md6 offset-md3>
                                                    <v-label>Avez-vous déjà été opéré ?</v-label>
                                                    <v-radio-group v-model="previousSurgeries" row>
                                                        <v-radio label="Oui" :value="true"></v-radio>
                                                        <v-radio label="Non" :value="false"></v-radio>
                                                    </v-radio-group>

                                                    <v-layout v-if="previousSurgeries" v-for="(surgery, index) in medical_histories" :key="'surgery-' + index">
                                                        <v-flex md4>
                                                            <v-text-field
                                                                :ref="'surgery_' + index"
                                                                :value="surgery.type"
                                                                @change="(value) => { setPreviousSurgeryType({index: index, data: value}) }"
                                                                label="Type" required>
                                                            </v-text-field>
                                                        </v-flex>
                                                        <v-flex md5>

                                                            <v-menu v-model="showSurgeryDatePicker[index]"
                                                                    :close-on-content-click="false"
                                                                    transition="scale-transition"
                                                                    offset-y
                                                                    full-width
                                                                    max-width="290px"
                                                                    min-width="290px">
                                                                <template v-slot:activator="{ on }">
                                                                    <v-text-field
                                                                        :value="surgery.date ? $options.filters.formatedDate(surgery.date) : ''"
                                                                        label="Date"
                                                                        append-icon="event"
                                                                        readonly
                                                                        v-on="on"
                                                                        append-outer-icon="clear" @click:append-outer="removeMedicalHistory(index)"
                                                                        required
                                                                        :rules="notEmpty"
                                                                    ></v-text-field>
                                                                </template>
                                                                <v-date-picker :value="surgery.date" no-title @input="(value) => {setPreviousSurgeryDate({index: index, data: value}); showSurgeryDatePicker[index] = false}"></v-date-picker>
                                                            </v-menu>
                                                        </v-flex>
                                                    </v-layout>
                                                    <v-layout v-if="previousSurgeries" >
                                                        <v-btn color="error" @click="addMedicalHistory"> Ajouter une opération</v-btn>
                                                    </v-layout>

                                                </v-flex>
                                            </v-layout>
                                        </v-container>
                                    </v-form>

                                </v-stepper-content>
                                <v-stepper-content step="6">
                                    <v-form ref="painForm" lazy-validation>
                                        <v-container grid-list-md>
                                            <v-layout>
                                                <v-flex md6 offset-md3>
                                                    <v-label>Avez-vous des douleurs ?</v-label>
                                                    <v-radio-group v-model="pain" row>
                                                        <v-radio label="Oui" :value="true"></v-radio>
                                                        <v-radio label="Non" :value="false"></v-radio>
                                                    </v-radio-group>

                                                    <v-select v-if="pain"
                                                        v-model="pain_since"
                                                        :items="[
                                                                {text: getText('SINCE_ACCIDENT'), value: 'SINCE_ACCIDENT'},
                                                                {text: getText('SINCE_LESS_THAN_A_MONTH'), value: 'SINCE_LESS_THAN_A_MONTH'},
                                                                {text: getText('SINCE_MORE_THAN_A_MONTH'), value: 'SINCE_MORE_THAN_A_MONTH'},
                                                                {text: getText('SINCE_SIX_MONTH'), value: 'SINCE_SIX_MONTH'},
                                                                {text: getText('SINCE_ONE_YEAR'), value: 'SINCE_ONE_YEAR'},
                                                                {text: getText('SINCE_TWO_YEARS'), value: 'SINCE_TWO_YEARS'},
                                                                {text: getText('SINCE_FIVE_YEARS'), value: 'SINCE_FIVE_YEARS'},
                                                                {text: getText('SINCE_TEN_YEARS'), value: 'SINCE_TEN_YEARS'}
                                                        ]"
                                                        label="Depuis combien de temps ?"
                                                        required
                                                    ></v-select>

                                                    <v-menu
                                                        v-if="pain && pain_since === 'SINCE_ACCIDENT'"
                                                        v-model="showPainAccidentDatePicker"
                                                            :close-on-content-click="false"
                                                            transition="scale-transition"
                                                            offset-y
                                                            full-width
                                                            max-width="290px"
                                                            min-width="290px">
                                                        <template v-slot:activator="{ on }">
                                                            <v-text-field
                                                                v-model="computedAccidentDateFormatted"
                                                                label="Date de l'accident"
                                                                append-icon="event"
                                                                readonly
                                                                v-on="on"
                                                                :rules="notEmpty"
                                                                required
                                                            ></v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="accident_date" no-title @input="showPainAccidentDatePicker = false"></v-date-picker>
                                                    </v-menu>

                                                    <v-label  v-if="pain">Douleurs à l’effort</v-label>
                                                    <v-radio-group v-model="pain_effort" row  v-if="pain">
                                                        <v-radio label="Oui" :value="1"></v-radio>
                                                        <v-radio label="Non" :value="0"></v-radio>
                                                    </v-radio-group>
                                                    <v-label  v-if="pain">Douleurs au repos</v-label>
                                                    <v-radio-group v-model="pain_rest" row  v-if="pain">
                                                        <v-radio label="Oui" :value="1"></v-radio>
                                                        <v-radio label="Non" :value="0"></v-radio>
                                                    </v-radio-group>
                                                    <v-label  v-if="pain">Douleurs la nuit</v-label>
                                                    <v-radio-group v-model="pain_night" row  v-if="pain">
                                                        <v-radio label="Oui" :value="1"></v-radio>
                                                        <v-radio label="Non" :value="0"></v-radio>
                                                    </v-radio-group>

                                                </v-flex>
                                            </v-layout>
                                        </v-container>
                                    </v-form>

                                </v-stepper-content>
                                <v-stepper-content step="7">
                                    <v-form ref="blockingForm" lazy-validation>
                                        <v-container grid-list-md >
                                            <v-layout>
                                                <v-flex md6 offset-md3>
                                                    <v-label>Avez-vous des blocages ?</v-label>
                                                    <v-radio-group v-model="blocking" row>
                                                        <v-radio label="Oui" :value="true"></v-radio>
                                                        <v-radio label="Non" :value="false"></v-radio>
                                                    </v-radio-group>

                                                    <v-select v-if="blocking"
                                                              v-model="blocking_since"
                                                              :items="[
                                                                {text: getText('SINCE_ACCIDENT'), value: 'SINCE_ACCIDENT'},
                                                                {text: getText('SINCE_LESS_THAN_A_MONTH'), value: 'SINCE_LESS_THAN_A_MONTH'},
                                                                {text: getText('SINCE_MORE_THAN_A_MONTH'), value: 'SINCE_MORE_THAN_A_MONTH'},
                                                                {text: getText('SINCE_SIX_MONTH'), value: 'SINCE_SIX_MONTH'},
                                                                {text: getText('SINCE_ONE_YEAR'), value: 'SINCE_ONE_YEAR'},
                                                                {text: getText('SINCE_TWO_YEARS'), value: 'SINCE_TWO_YEARS'},
                                                                {text: getText('SINCE_FIVE_YEARS'), value: 'SINCE_FIVE_YEARS'},
                                                                {text: getText('SINCE_TEN_YEARS'), value: 'SINCE_TEN_YEARS'}
                                                        ]"
                                                              label="Depuis combien de temps ?"
                                                              required
                                                    ></v-select>

                                                    <v-menu
                                                        v-if="blocking && blocking_since === 'SINCE_ACCIDENT'"
                                                        v-model="showBlockingAccidentDatePicker"
                                                        :close-on-content-click="false"
                                                        transition="scale-transition"
                                                        offset-y
                                                        full-width
                                                        max-width="290px"
                                                        min-width="290px">
                                                        <template v-slot:activator="{ on }">
                                                            <v-text-field
                                                                v-model="computedAccidentDateFormatted"
                                                                label="Date de l'accident"
                                                                append-icon="event"
                                                                readonly
                                                                v-on="on"
                                                                :rules="notEmpty"
                                                                required
                                                            ></v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="accident_date" no-title @input="showBlockingAccidentDatePicker = false"></v-date-picker>
                                                    </v-menu>
                                                </v-flex>
                                            </v-layout>
                                        </v-container>
                                    </v-form>

                                </v-stepper-content>
                                <v-stepper-content step="8">
                                    <v-form ref="instabilityForm" lazy-validation>
                                        <v-container grid-list-md >
                                            <v-layout>
                                                <v-flex md6 offset-md3>
                                                    <v-label>Avez-vous des sensations d'instabilité ?</v-label>
                                                    <v-radio-group v-model="instability" row>
                                                        <v-radio label="Oui" :value="true"></v-radio>
                                                        <v-radio label="Non" :value="false"></v-radio>
                                                    </v-radio-group>

                                                    <v-select v-if="instability"
                                                              v-model="instability_since"
                                                              :items="[
                                                                {text: getText('SINCE_ACCIDENT'), value: 'SINCE_ACCIDENT'},
                                                                {text: getText('SINCE_LESS_THAN_A_MONTH'), value: 'SINCE_LESS_THAN_A_MONTH'},
                                                                {text: getText('SINCE_MORE_THAN_A_MONTH'), value: 'SINCE_MORE_THAN_A_MONTH'},
                                                                {text: getText('SINCE_SIX_MONTH'), value: 'SINCE_SIX_MONTH'},
                                                                {text: getText('SINCE_ONE_YEAR'), value: 'SINCE_ONE_YEAR'},
                                                                {text: getText('SINCE_TWO_YEARS'), value: 'SINCE_TWO_YEARS'},
                                                                {text: getText('SINCE_FIVE_YEARS'), value: 'SINCE_FIVE_YEARS'},
                                                                {text: getText('SINCE_TEN_YEARS'), value: 'SINCE_TEN_YEARS'}
                                                        ]"
                                                              label="Depuis combien de temps ?"
                                                              required
                                                    ></v-select>

                                                    <v-menu
                                                        v-if="instability && instability_since === 'SINCE_ACCIDENT'"
                                                        v-model="showInstabilityAccidentDatePicker"
                                                        :close-on-content-click="false"
                                                        transition="scale-transition"
                                                        offset-y
                                                        full-width
                                                        max-width="290px"
                                                        min-width="290px">
                                                        <template v-slot:activator="{ on }">
                                                            <v-text-field
                                                                v-model="computedAccidentDateFormatted"
                                                                label="Date de l'accident"
                                                                append-icon="event"
                                                                readonly
                                                                v-on="on"
                                                                :rules="notEmpty"
                                                                required
                                                            ></v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="accident_date" no-title @input="showInstabilityAccidentDatePicker = false"></v-date-picker>
                                                    </v-menu>
                                                </v-flex>
                                            </v-layout>
                                        </v-container>
                                    </v-form>

                                </v-stepper-content>
                                <v-stepper-content step="9">
                                    <v-form ref="inflatedForm" lazy-validation>
                                        <v-container grid-list-md>
                                            <v-layout>
                                                <v-flex md6 offset-md3>
                                                    <v-label>Avez-vous remarqué un gonflement ?</v-label>
                                                    <v-radio-group v-model="inflated" row>
                                                        <v-radio label="Oui" :value="true"></v-radio>
                                                        <v-radio label="Non" :value="false"></v-radio>
                                                    </v-radio-group>

                                                    <v-select v-if="inflated"
                                                              v-model="inflated_since"
                                                              :items="[
                                                                {text: getText('SINCE_ACCIDENT'), value: 'SINCE_ACCIDENT'},
                                                                {text: getText('SINCE_LESS_THAN_A_MONTH'), value: 'SINCE_LESS_THAN_A_MONTH'},
                                                                {text: getText('SINCE_MORE_THAN_A_MONTH'), value: 'SINCE_MORE_THAN_A_MONTH'},
                                                                {text: getText('SINCE_SIX_MONTH'), value: 'SINCE_SIX_MONTH'},
                                                                {text: getText('SINCE_ONE_YEAR'), value: 'SINCE_ONE_YEAR'},
                                                                {text: getText('SINCE_TWO_YEARS'), value: 'SINCE_TWO_YEARS'},
                                                                {text: getText('SINCE_FIVE_YEARS'), value: 'SINCE_FIVE_YEARS'},
                                                                {text: getText('SINCE_TEN_YEARS'), value: 'SINCE_TEN_YEARS'}
                                                        ]"
                                                              label="Depuis combien de temps ?"
                                                              required
                                                    ></v-select>

                                                    <v-menu
                                                        v-if="inflated && inflated_since === 'SINCE_ACCIDENT'"
                                                        v-model="showInflatedAccidentDatePicker"
                                                        :close-on-content-click="false"
                                                        transition="scale-transition"
                                                        offset-y
                                                        full-width
                                                        max-width="290px"
                                                        min-width="290px">
                                                        <template v-slot:activator="{ on }">
                                                            <v-text-field
                                                                v-model="computedAccidentDateFormatted"
                                                                label="Date de l'accident"
                                                                append-icon="event"
                                                                readonly
                                                                v-on="on"
                                                                :rules="notEmpty"
                                                                required
                                                            ></v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="accident_date" no-title @input="showInflatedAccidentDatePicker = false"></v-date-picker>
                                                    </v-menu>

                                                    <v-select v-if="inflated"
                                                              v-model="inflated_count"
                                                              :items="[
                                                                {text: 'Une seule fois', value: 1},
                                                                {text: 'Plusieurs fois', value: 2}
                                                        ]"
                                                              label="Combien de fois ?"
                                                              required
                                                    ></v-select>

                                                </v-flex>
                                            </v-layout>
                                        </v-container>
                                    </v-form>

                                </v-stepper-content>
                            </v-stepper-items>
                        </v-stepper>

                    </v-container>
                </v-card-title>


            </v-flex>
            <v-flex xs12>
                <v-card-actions class="absolute-bottom-right">
                    <v-btn v-if="step > 1" @click="(step > 1) ? step = step - 1 : null" class="left">
                        Précédent
                    </v-btn>
                    <v-btn color="success" @click="validate" class="right"> Suivant </v-btn>
                </v-card-actions>
            </v-flex>
        </v-layout>

    </v-container>

</template>

<script>
    import { mapActions, mapState, mapMutations } from 'vuex'
    import { getTranslationString } from "../../../helper";

    export default {
        name: "MedicalCaseForm",
        data: () => ({
            showSurgeryDatePicker: [false],
            showPainAccidentDatePicker: false,
            showInflatedAccidentDatePicker: false,
            showInstabilityAccidentDatePicker: false,
            showBlockingAccidentDatePicker: false,
            surgeryFormValid: false,
            step: 1,
            finalStep: 10,
            isSmoker: null,
            previousSurgeries: false,
            pain: false,
            blocking: false,
            instability: false,
            inflated: false,
            none: null,
            notEmpty: [
                v => !!v || 'Le champs est requis'
            ],
        }),
        created() {
            //this.updateLockedState("true")
            this.setSelectedMedicalCaseId(this.$route.params.medical_case_id)
            this.getMedicalCaseInfo().then(response => {
                this.pain = this.pain_since == null ? null : true
                this.isSmoker = this.smoking_quantity == null ? null : true
                this.blocking = this.blocking_since == null ? null : true
                this.previousSurgeries = this.medical_histories.length === 0 ? null : true
                this.instability = this.instability_since == null ? null : true
                this.inflated = this.inflated_since == null ? null : true
            })

        },
        mounted() {
            if (this.$route.params.locked !== false) {
                this.updateLockedState("true")
                this.setLockedBack(this.$route.params.back);
            }
            this.$refs.articulationForm.reset()
            this.$refs.bmiForm.reset()
            this.$refs.smokingForm.reset()
            this.$refs.diseasesForm.reset()
            this.$refs.surgeryForm.reset()
            this.$refs.painForm.reset()
            this.$refs.blockingForm.reset()
            this.$refs.instabilityForm.reset()
            this.$refs.inflatedForm.reset()
        },
        computed: {
            diseasesRules() {
                return [
                    (this.step !== 4 || (this.diabetes
                     || this.hypertension
                     || this.cholesterol
                     || this.arthritis
                     || this.fibrillation
                     || this.coagulation_disorder
                     || this.none)) || "Merci de sélectionner au moins une valeur"
                ];
            },
            computedAccidentDateFormatted: {
                get() {
                    return this.accident_date ? this.$options.filters.formatedDate(this.accident_date) : ''
                }
            },
            height: {
                get() {
                    return this.$store.state.medical_cases.form.height
                },
                set(value) {
                    this.setHeight(value)
                }
            },
            weight: {
                get() {
                    return this.$store.state.medical_cases.form.weight
                },
                set(value) {
                    this.setWeight(value)
                }
            },
            articulation: {
                get() {
                    return this.$store.state.medical_cases.form.articulation
                },
                set(value) {
                    this.setArticulation(value)
                }
            },
            smoking_quantity: {
                get() {
                    return this.$store.state.medical_cases.form.smoking_quantity
                },
                set(value) {
                    this.setSmokingQuantity(value)
                }
            },
            diabetes: {
                get() {
                    return this.$store.state.medical_cases.form.diabetes
                },
                set(value) {
                    this.setDiabetes(value)
                }
            },
            hypertension: {
                get() {
                    return this.$store.state.medical_cases.form.hypertension
                },
                set(value) {
                    this.setHypertension(value)
                }
            },
            cholesterol: {
                get() {
                    return this.$store.state.medical_cases.form.cholesterol
                },
                set(value) {
                    this.setCholesterol(value)
                }
            },
            arthritis: {
                get() {
                    return this.$store.state.medical_cases.form.arthritis
                },
                set(value) {
                    this.setArthritis(value)
                }
            },
            fibrillation: {
                get() {
                    return this.$store.state.medical_cases.form.fibrillation
                },
                set(value) {
                    this.setFibrillation(value)
                }
            },
            coagulation_disorder: {
                get() {
                    return this.$store.state.medical_cases.form.coagulation_disorder
                },
                set(value) {
                    this.setCoagulationDisorder(value)
                }
            },
            pain_since: {
                get() {
                    return this.$store.state.medical_cases.form.pain_since
                },
                set(value) {
                    this.setPainSince(value)
                }
            },
            pain_effort: {
                get() {
                    return this.$store.state.medical_cases.form.pain_effort
                },
                set(value) {
                    this.setPainEffort(value)
                }
            },
            pain_rest: {
                get() {
                    return this.$store.state.medical_cases.form.pain_rest
                },
                set(value) {
                    this.setPainRest(value)
                }
            },
            pain_night: {
                get() {
                    return this.$store.state.medical_cases.form.pain_night
                },
                set(value) {
                    this.setPainNight(value)
                }
            },
            accident_date: {
                get() {
                    return this.$store.state.medical_cases.form.accident_date
                },
                set(value) {
                    this.setAccidentDate(value)
                }
            },
            blocking_since: {
                get() {
                    return this.$store.state.medical_cases.form.blocking_since
                },
                set(value) {
                    this.setBlockingSince(value)
                }
            },
            instability_since: {
                get() {
                    return this.$store.state.medical_cases.form.instability_since
                },
                set(value) {
                    this.setInstabilitySince(value)
                }
            },
            inflated_since: {
                get() {
                    return this.$store.state.medical_cases.form.inflated_since
                },
                set(value) {
                    this.setInflatedSince(value)
                }
            },
            inflated_count: {
                get() {
                    return this.$store.state.medical_cases.form.inflated_count
                },
                set(value) {
                    this.setInflatedCount(value)
                }
            },
            medical_histories: {
                get() {
                    return this.$store.state.medical_cases.form.medical_histories
                }
            },
            ...mapState('patients', {
                selectedPatient: state => state.selectedPatient,
            }),
            ...mapState('medical_cases', {
                name: state => state.selectedMedicalCase.name,
                medical_case_id: state => state.selectedMedicalCase.id
            })
        },
        methods: {
            back() {
                this.$router.back()
            },
            validate () {
                if (this.step === 4) {
                    if (this.$refs.diseasesForm.validate()) {
                        this.submit()
                    }
                }
                else {
                    if (this.$refs.bmiForm.validate() && this.$refs.surgeryForm.validate()) {
                        this.submit()
                    }
                }

            },
            submit() {
                this.updateMedicalForm().then(response => {
                    //Move back to the user list
                    //this.$refs.form.reset()
                    this.step = this.step + 1
                    this.$refs.diseasesForm.resetValidation()

                    if (this.step >= this.finalStep) {
                        this.$router.replace({name: 'patient.viewMedicalCase', params: { medical_case_id: this.medical_case_id }})
                    }
                }).catch(reason => {
                    //Stay here..
                    console.log(reason)
                })
            },
            getText(key) {
                return getTranslationString(key)
            },
            ...mapMutations('medical_cases', {
                setHeight: 'setFormHeight',
                setWeight: 'setFormWeight',
                setArticulation: 'setFormArticulation',
                setSmokingQuantity: 'setFormSmokingQuantity',
                setSelectedMedicalCaseId: 'setSelectedMedicalCaseId',
                setDiabetes: 'setFormDiabetes',
                setHypertension: 'setFormHypertension',
                setCholesterol: 'setFormCholesterol',
                setArthritis: 'setFormArthritis',
                setFibrillation: 'setFormFibrillation',
                setCoagulationDisorder: 'setFormCoagulationDisorder',
                setPreviousSurgeryType: 'setPreviousSurgeryType',
                setPreviousSurgeryDate: 'setPreviousSurgeryDate',
                removeMedicalHistory: 'removeMedicalHistory',
                addMedicalHistory: 'addMedicalHistory',
                setPainSince: 'setFormPainSince',
                setPainEffort: 'setFormPainEffort',
                setPainRest: 'setFormPainRest',
                setPainNight: 'setFormPainNight',
                setAccidentDate: 'setFormAccidentDate',
                setBlockingSince: 'setFormBlockingSince',
                setInstabilitySince: 'setFormInstabilitySince',
                setInflatedSince: 'setFormInflatedSince',
                setInflatedCount: 'setFormInflatedCount',
            }),
            ...mapMutations(['updateLockedState', 'setLockedBack']),
            ...mapActions('medical_cases', ['createMedicalCase', 'getMedicalCaseInfo', 'updateMedicalForm']),
        }
    }
</script>
