<template>
    <v-layout>
        <v-flex>
            <v-card-text>
                <v-container grid-list-md py-2>
                    <v-layout row wrap>
                        <v-flex>
                            <v-layout>
                                <v-flex>
                                    <h3>Traitement : {{ getText(selectedTreatment.type) }}</h3>
                                    <h3>Diagnostique : {{ getText(selectedTreatment.medical_case.diagnostic.type)
                                        }}</h3>
                                    <p>
                                        Ouvert le : {{ selectedTreatment.created_at | shortDate }}
                                    </p>

                                    <p>{{ selectedTreatment.description }}</p>

                                </v-flex>
                                <v-spacer></v-spacer>
                                <v-btn flat @click="treatmentModal()" v-if="selectedTreatment.open">
                                    <v-icon>edit</v-icon>
                                </v-btn>

                            </v-layout>
                        </v-flex>
                        <v-dialog max-width="600px" persistent v-model="showTreatmentForm" v-if="selectedTreatment.open">
                            <treatment-form></treatment-form>
                        </v-dialog>
                    </v-layout>
                    <v-layout row wrap>
                        <v-flex sm4 v-for="followUp in selectedTreatment.follow_ups " :key="followUp.id">
                            <v-card>
                                <v-card-title>
                                    <span v-if="followUp.follow_up_definition" class="headline">{{followUp.follow_up_definition.name}}</span>
                                    <v-spacer></v-spacer>
                                    <v-btn flat icon color="primary" class="ma-0" v-if="followUp.completed"
                                           @click="showResult(followUp.id)">
                                        <v-icon>mdi-eye-check-outline</v-icon>
                                    </v-btn>
                                    <v-btn v-else flat icon disabled class="ma-0"><v-icon>null</v-icon></v-btn>
                                </v-card-title>
                                <v-card-text>
                                    <p>Statut : {{ getTextStatus(followUp) }} le {{ followUp.updated_at | shortDate
                                        }}</p>

                                    <h4>Scores:</h4>
                                    <template v-if="followUp.completed">
                                        <ul>
                                            <li v-for="follow_up_score in followUp.follow_up_scores" :key="follow_up_score.id">
                                                {{follow_up_score.score.name}} : {{ formatScore(follow_up_score.value, follow_up_score.score.rule)}}
                                            </li>
                                        </ul>
                                    </template>
                                    <template v-else>
                                        <ul v-if="selectedTreatment.scores_definition.length">
                                            <li v-for="score in selectedTreatment.scores_definition" :key="score.id">
                                                {{score.score.name}}
                                            </li>
                                        </ul>
                                        <p v-else>Aucun score disponible pour ce diagnostique</p>
                                    </template>
                                </v-card-text>
                                <v-card-actions v-if="selectedTreatment.scores_definition.length">
                                    <template
                                        v-if="selectedTreatment.open && (followUp.follow_up_definition_id == selectedTreatment.next_follow_up.follow_up_definition_id)">
                                        <v-btn flat text color="primary"
                                               @click="showModal(followUp.follow_up_definition_id)">Continuer
                                        </v-btn>
                                        <v-btn flat text color="secondary"
                                               @click="showSkipModal(followUp.follow_up_definition_id)">Passer
                                        </v-btn>
                                    </template>
                                    <v-btn v-else-if="followUp.completed" flat class=" custom-disabled">
                                        <v-icon color="green darken-1" medium>mdi-calendar-check</v-icon>
                                    </v-btn>
                                    <v-btn v-else-if="followUp.skip" flat class="custom-disabled">
                                        <v-icon color="red darken-2" medium>mdi-calendar-remove</v-icon>
                                    </v-btn>
                                    <v-btn v-else flat icon disabled>
                                        <v-icon color="grey darken-1" medium>mdi-calendar-clock</v-icon>
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-flex>
                        <v-flex sm4 v-for="followUp in selectedTreatment.next_follow_ups " :key="followUp.id">
                            <v-card>
                                <v-card-title>
                                    <span class="headline">{{followUp.name}}</span>
                                    <v-spacer></v-spacer>
                                    <v-btn flat icon disabled class="ma-0"><v-icon>null</v-icon></v-btn>
                                </v-card-title>
                                <v-card-text>
                                    <p>Statut : {{ getTextStatus('FOLLOW_UP_STATUS_NEXT') }}</p>

                                    <h4>Scores:</h4>
                                    <ul v-if="selectedTreatment.scores_definition.length">
                                        <li v-for="score in selectedTreatment.scores_definition" :key="score.id">
                                            {{score.score.name}}
                                        </li>
                                    </ul>
                                    <p v-else>Aucun score disponible pour ce diagnostique</p>
                                </v-card-text>
                                <v-card-actions v-if="selectedTreatment.open && (selectedTreatment.scores_definition.length)">
                                    <template v-if="followUp.id === selectedTreatment.next_follow_up.id">
                                        <v-btn flat color="primary" @click="showModal(followUp.id)">Commencer</v-btn>
                                        <v-btn flat color="secondary" @click="showSkipModal(followUp.id)">Passer</v-btn>
                                    </template>
                                    <v-btn v-else flat disabled>
                                        <v-icon color="grey darken-1" medium>mdi-calendar-clock</v-icon>
                                    </v-btn>
                                </v-card-actions>
                                <v-card-actions v-else>
                                    <v-btn flat disabled>
                                        <v-icon color="grey darken-1" medium>null</v-icon>
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-flex>

                    </v-layout>
                    <v-layout justify-center>
                        <v-dialog v-model="dialog" persistent max-width="480">
                            <v-card>
                                <v-card-title class="headline">Suivi Patient</v-card-title>
                                <v-card-text>Voulez-vous vérouiller la tablette et activer la saisie patient ?
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="secondary" text @click="startScore(false)">Non, continuer</v-btn>
                                    <v-btn color="primary" text @click="startScore(true)">Oui, verrouiller</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialog_skip" persistent max-width="480">
                            <v-card>
                                <v-card-title class="headline">Passer le suivi</v-card-title>
                                <v-card-text>Etes-vous sûr vouloir de passer ce suivi ?
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="secondary" text @click="dialog_skip = false">Non</v-btn>
                                    <v-btn color="primary" text @click="skipScore()">Oui</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-layout>
                </v-container>
            </v-card-text>
        </v-flex>
    </v-layout>
</template>

<script>
    import {mapActions, mapState, mapMutations} from 'vuex';
    import TreatmentForm from "../treatment/TreatmentForm";
    import {getTranslationString} from "../../../helper";

    export default {
        name: "TreatmentView",
        components: {
            'treatment-form': TreatmentForm
        },
        data: () => ({
            dialog: false,
            dialog_skip: false,
            locked: false,
            selectedFollowUpDefinition: null,
        }),
        created() {
            this.setSelectedMedicalCaseId(this.$route.params.medical_case_id)
            this.setSelectedTreatmentId(this.$route.params.treatment_id)
            this.getTreatmentInfo()
        },
        computed: {
            ...mapState('medical_cases', {
                selectedMedicalCase: state => state.selectedMedicalCase,
            }),
            ...mapState('treatments', {
                selectedTreatment: state => state.selectedTreatment,
                showTreatmentForm: state => state.showForm
            }),
            ...mapState('patients', {
                selectedPatient: state => state.selectedPatient,
            })
        },
        methods: {
            ...mapMutations(['updateLockedState']),
            ...mapMutations('medical_cases', [
                'setSelectedMedicalCaseId'
            ]),
            ...mapActions('treatments', [
                'getTreatmentInfo',
            ]),
            ...mapActions('follow_ups', ['createFollowUp']),
            ...mapMutations('treatments', [
                'setSelectedTreatmentId', 'initForm', 'setShowForm', 'resetForm'
            ]),
            getText(key) {
                return getTranslationString(key)
            },
            getTextStatus(followUp) {
                if (followUp.skip) {
                    return this.getText('FOLLOW_UP_STATUS_SKIP')
                } else {
                    return followUp.completed ? this.getText('FOLLOW_UP_STATUS_COMPLETED') : this.getText('FOLLOW_UP_STATUS_UNCOMPLETED')
                }
            },
            treatmentModal() {
                this.initForm(this.selectedTreatment);
                this.setShowForm(true);
            },
            showModal(followUpDefinition) {
                this.dialog = true;
                this.selectedFollowUpDefinition = followUpDefinition
            },
            showSkipModal(followUpDefinition) {
                this.dialog_skip = true;
                this.selectedFollowUpDefinition = followUpDefinition
            },
            startScore(locked) {
                if (this.selectedFollowUpDefinition != null) {
                    this.createFollowUp({
                        follow_up_definition_id: this.selectedFollowUpDefinition,
                        skip: false
                    }).then(response => {
                        this.$router.push({
                            name: 'patient.openFollowUp', params: {
                                locked: locked,
                                back: this.$route.fullPath,
                                patient_id: this.selectedPatient.id,
                                medical_case_id: this.selectedMedicalCase.id,
                                treatment_id: this.selectedTreatment.id,
                                follow_up_id: response.data.follow_up.id
                            }
                        })

                    }).catch(reason => {
                        //Stay here..
                    })
                }
            },
            showResult(followUp) {
                this.$router.push({
                    name: 'patient.viewFollowUp', params: {
                        patient_id: this.selectedPatient.id,
                        medical_case_id: this.selectedMedicalCase.id,
                        treatment_id: this.selectedTreatment.id,
                        follow_up_id: followUp
                    }
                })
            },
            skipScore() {
                console.log(this.selectedFollowUpDefinition)
                if (this.selectedFollowUpDefinition != null) {
                    this.createFollowUp({
                        follow_up_definition_id: this.selectedFollowUpDefinition,
                        skip: true
                    }).then(response => {
                        this.dialog_skip = false
                        this.getTreatmentInfo()
                    }).catch(reason => {
                        //Stay here..
                    })
                }
            },
            formatScore(score, rule) {
                if (rule === 'sum') {
                    return score.toFixed(2)
                } else {
                    return score.toFixed(2) + " % "
                }
            },
        }
    }
</script>

<style scoped>
    .custom-disabled {
        box-shadow: none !important;
        pointer-events: none;
    }
</style>
