import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify'
import fr from 'vuetify/es5/locale/fr'
import VueAuth from '@websanova/vue-auth'
import VueAxios from 'vue-axios'

import 'es6-promise/auto'
import 'vuetify/dist/vuetify.min.css'

import App from './pages/App'
import router from './router'
import auth from './auth'
import axios from 'axios'
import store from './store'

import moment from 'moment';
moment.locale('fr');

Vue.use(Vuetify, {
    iconfont: 'mdi',
    lang: {
        locales: {fr},
        current: 'fr'
    },
    theme: {
        primary: '#00B6E0',
        secondary: '#3EC8E4',
        accent: '#ff5722',
        error: '#f44336',
        warning: '#ffc107',
        info: '#3EC8E4',
        success: '#63CED0'
    }
})

// Set Vue globally
window.Vue = Vue
Vue.router = router

// Set Vue authentication
Vue.use(VueAxios, axios)
axios.defaults.baseURL = `${process.env.MIX_APP_URL}/api`
window.appName = `${process.env.MIX_APP_NAME}`
Vue.use(VueAuth, auth)

Vue.filter('shortDate', function(value) {
    return moment(value).format("L")
})

Vue.filter('formatedDate', function(value) {
    return moment(value).format("L")
})

const app = new Vue({
    el: '#app',
    store,
    router,
    render: h => h(App)
});

//Hack to get access to axios in the store
store.commit('setAxios', app.$http)
