<template>
    <v-layout align-center justify-center lock-screen>
        <v-flex xs12 lg8>
            <v-card>
                <v-card-title primary-title>
                    <div>
                        <h3 class="title" grow>HLT Suivi Patient</h3><br>
                        <span class="subtitle grey--text">Merci de rendre la tablette au secrétariat</span>
                        <v-spacer></v-spacer>
                        <v-form v-if="nb_try < 3" ref="form" lazy-validation>
                            <v-container grid-list-md text-xs-center>
                                <v-layout row wrap align-center>
                                    <v-flex offset-xs4 xs1>
                                        <v-card dark color="secondary">
                                            <v-card-text class="px-0">
                                                <input @keyup="updateInput($event, 'input2')" maxlength="1"
                                                       v-model="input1" ref="input1" @focusin="input1 = ''"
                                                       type="text" pattern="[0-9]*" inputmode="numeric"
                                                       min="0" max="9"
                                                       required autofocus>
                                            </v-card-text>
                                        </v-card>
                                    </v-flex>
                                    <v-flex xs1>
                                        <v-card dark color="secondary">
                                            <v-card-text class="px-0">
                                                <input @keyup="updateInput($event, 'input3')" maxlength="1"
                                                       v-model="input2" ref="input2"
                                                       type="text" pattern="[0-9]*" inputmode="numeric"
                                                       min="0" max="9"
                                                       @focusin="input2 = ''; input1 === '' ? moveInputToPrevious('input1') : null"
                                                       >
                                            </v-card-text>
                                        </v-card>
                                    </v-flex>
                                    <v-flex xs1>
                                        <v-card dark color="secondary">
                                            <v-card-text class="px-0">
                                                <input @keyup="updateInput($event, 'input4')" maxlength="1"
                                                       v-model="input3" ref="input3"
                                                       type="text" pattern="[0-9]*" inputmode="numeric"
                                                       min="0" max="9"
                                                       @focusin="input3 = ''; input2 === '' ? moveInputToPrevious('input2') : null"
                                                       >
                                            </v-card-text>
                                        </v-card>
                                    </v-flex>
                                    <v-flex xs1>
                                        <v-card dark color="secondary">
                                            <v-card-text class="px-0">
                                                <input @keyup="updateInput" maxlength="1" v-model="input4" ref="input4"
                                                       type="text" pattern="[0-9]*" inputmode="numeric"
                                                       min="0" max="9"
                                                       @focusin="input4 = ''; input3 === '' ? moveInputToPrevious('input3') : null"
                                                       >
                                            </v-card-text>
                                        </v-card>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </v-form>
                    </div>
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div>
                        Pas vous? <a @click.prevent="$auth.logout()">Déconnexion</a>
                    </div>
                </v-card-actions>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
    import {mapActions, mapMutations, mapState} from 'vuex'

    export default {
        name: 'LockScreen',
        data() {
            return {
                email: null,
                password: null,
                has_error: false,
                error_message: String,
                input1: '',
                input2: '',
                input3: '',
                input4: '',
                nb_try: 0,
            }
        },

        methods: {
            moveInputToPrevious(ref) {
                this.$refs[ref].focus()
            },
            updateInput(event, next) {
                if (next) {
                    this.$refs[next].focus()
                } else {
                    this.unlock({code: "" + this.input1 + this.input2 + this.input3 + this.input4}).then(response => {
                        //Move back to the user list
                        if (this.goBack != null) {
                            this.$router.replace({ path: this.goBack })
                            this.setLockedBack(null)
                        } else {
                            if (this.selectedPatient.id !== undefined) {
                                this.$router.replace({name: 'patient.view', params: {id: this.selectedPatient.id}})
                            } else {
                                this.$router.replace({name: 'home'})
                            }
                        }

                    }).catch(reason => {
                        this.input1 = ''
                        this.input2 = ''
                        this.input3 = ''
                        this.input4 = ''

                        this.$refs.input1.focus()
                        this.nb_try++

                        //if (this.nb_try > 2) this.$auth.logout()
                    })
                }
            },
            ...mapActions(["unlock"]),
            ...mapMutations(["updateLockedState", "setLockedBack"])
        },
        computed: {
            ...mapState('patients', {
                selectedPatient: state => state.selectedPatient
            }),
            ...mapState({
                goBack: state => state.back
            })
        },
        mounted() {
            this.$refs.input1.focus()
        }
    }
</script>

<style scoped>

</style>
