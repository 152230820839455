<template>
    <div id="nav" v-if="locked == 'null'">
        <v-navigation-drawer v-model="drawer" light app>
            <v-toolbar flat>
                <v-list>
                    <v-list-tile>
                        <v-list-tile-title class="title">
                            <slot name="title"></slot>
                        </v-list-tile-title>
                    </v-list-tile>
                </v-list>
            </v-toolbar>

            <v-divider></v-divider>

            <v-list dense class="pt-0">
                <v-list-tile v-for="item in items" :key="item.title" :to="item.to" v-if="item.admin == false || isAdmin">
                    <v-list-tile-action>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-tile-action>

                    <v-list-tile-content>
                        <v-list-tile-title>{{ item.title }}</v-list-tile-title>
                    </v-list-tile-content>
                </v-list-tile>

                <v-list-tile @click="exportData">
                    <v-list-tile-action>
                        <v-icon>mdi-database-export</v-icon>
                    </v-list-tile-action>

                    <v-list-tile-content>
                        <v-list-tile-title>Export results</v-list-tile-title>
                    </v-list-tile-content>
                </v-list-tile>
            </v-list>
        </v-navigation-drawer>
        <v-toolbar fixed app dark color="primary">
            <v-toolbar-side-icon @click.stop="drawer = !drawer"></v-toolbar-side-icon>
            <v-spacer></v-spacer>
            <v-toolbar-title><img src="/img/logo.png" :alt="title" aspect-ratio="1" height="64px" hlt-logo></v-toolbar-title>
            <v-spacer></v-spacer>
            {{ username }}
            <v-btn icon @click.prevent="$auth.logout()">
                <v-icon>logout</v-icon>
            </v-btn>
        </v-toolbar>
    </div>
</template>

<script>
    import { mapActions, mapState, mapMutations } from 'vuex'

    export default {
        name: "Navigation",
        data: () => ({
            title: window.appName,
            items: [
                { title: 'Liste des patients', icon: 'dashboard', to: {name: 'home'}, admin: false },
                /*{ title: 'Admin', icon: 'settings', to: {name: 'admin.dashboard'}, admin: true }*/
            ],
            drawer: false
        }),
        computed: {
            ...mapState({
                locked: state => state.locked
            }),
            isAdmin() {
                return this.$auth.user().role === 'ADMIN'
            },
            username() {
                return this.$auth.user().name
            }

        },
        methods: {
            ...mapActions('follow_ups', [
                'exportAllResults'
            ]),
            exportData() {
                this.exportAllResults().then(response => {
                    let fileName = response.headers["content-disposition"].split("filename=")[1];
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE variant
                        window.navigator.msSaveOrOpenBlob(new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}),
                            fileName);
                    } else {
                        const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', response.headers["content-disposition"].split("filename=")[1]);
                        document.body.appendChild(link);
                        link.click();
                    }
                }).catch(reason => {
                    //Stay here..
                })
            }
        }
    }
</script>
