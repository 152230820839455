import patients from '../../api/patients'

const errors = {
    GET_ALL_PATIENTS_LOADING_ERROR: 'Erreur lors du chargement des patients, merci de réessayer',
    GET_TEGNER_LOADING_ERROR: 'Erreur lors du chargement, merci de réessayer',
    GET_PATIENT_LOADING_ERROR: 'Erreur lors du chargement du patient, merci de réessayer',
    CREATE_PATIENT_ERROR: 'Erreur lors de la création du patient, merci de réessayer',
    UPDATE_PATIENT_ERROR: 'Erreur lors de la mise à jour du patient, merci de réessayer',
    UPDATE_PATIENT_CONTACT_INFOS_ERROR: 'Erreur lors de la mise à jour des informations de contact du patient, merci de réessayer',
    UPDATE_PATIENT_DOCTORS_INFOS_ERROR: 'Erreur lors de la mise à jour du patient, merci de réessayer',
}

const success = {
    CREATE_PATIENT_SUCCESS: {
        title: "Succès",
        content: "Patient créé avec succès"
    },
    UPDATE_PATIENT_SUCCESS: {
        title: "Succès",
        content: "Patient mis à jour"
    },
    UPDATE_PATIENT_CONTACT_INFOS_SUCCESS: {
        title: "Succès",
        content: "Informations de contact du patient mis à jour"
    },
    UPDATE_PATIENT_DOCTORS_INFOS_SUCCESS: {
        title: "Succès",
        content: "Informations du patient mis à jour"
    },
}


// initial state
const state = {
    all: [],
    tegnerScore: {
        score_question_answer: null,
        score_question_text: ''
    },
    selectedPatient: {
        lastname: '',
        firstname: '',
        birth_date: null,
    },
    form: {
        gender: '',
        firstname: '',
        lastname: '',
        birth_date: null,
    },
    contactForm: {
        email: '',
        phone: '',
        address: '',
        postcode: '',
        city: '',
        country: 'Suisse',
    },
    doctorsForm: {
        main: {
            name: ''
        },
        physio: {
            name: ''
        },
        others: [{
            name: '',
            type: 'OTHER',
            label: null
        }]
    },
    firmForm: {
        firm_select: '',
        firm_select_other: '',
    },
    jobForm: {
        job: '',
    },
    tegnerForm: {
        tegner: null,
    },
}

// getters
const getters = {}

// actions
const actions = {
    getAllPatients ({ state, commit, rootState }) {
        patients.getPatients(rootState.axios).then(result => {
            commit('setPatients', result.data.patients)
        }).catch(reason => {
            commit('notifications/showError', {
                title: errors.GET_ALL_PATIENTS_LOADING_ERROR, content: reason
            }, { root: true })

        })
    },
    createPatient({ state, commit, rootState }) {

        return new Promise((resolve, reject) => {
            patients.add(state.form, rootState.axios).then(result => {
                commit('notifications/showSuccess', {
                    title: success.CREATE_PATIENT_SUCCESS.title, content: success.CREATE_PATIENT_SUCCESS.content
                }, { root: true })
                resolve(result)
            }).catch(reason => {
                commit('notifications/showError', {
                    title: errors.CREATE_PATIENT_ERROR, content: reason
                }, { root: true })
                reject(reason)
            })
        });
    },
    updatePatient({ state, commit, rootState }) {

        return new Promise((resolve, reject) => {
            patients.update(state.selectedPatient.id, state.form, rootState.axios).then(result => {
                commit('setSelectedPatient', result.data.patient)
                commit('notifications/showSuccess', {
                    title: success.UPDATE_PATIENT_SUCCESS.title, content: success.UPDATE_PATIENT_SUCCESS.content
                }, { root: true })

                resolve(result)
            }).catch(reason => {
                commit('notifications/showError', {
                    title: errors.UPDATE_PATIENT_ERROR, content: reason
                }, { root: true })
                reject(reason)
            })
        });
    },
    getPatientInfo({ state, commit, rootState }) {
        patients.get(state.selectedPatient.id, rootState.axios).then(result => {
            commit('setSelectedPatient', result.data.patient)
        }).catch(reason => {
            commit('notifications/showError', {
                title: errors.GET_PATIENT_LOADING_ERROR, content: reason
            }, { root: true })

        })
    },
    getTegner({ state, commit, rootState }) {
        patients.getTegner(rootState.axios).then(result => {
            commit('setTegner', result.data.tegner)
        }).catch(reason => {
            commit('notifications/showError', {
                title: errors.GET_TEGNER_LOADING_ERROR, content: reason
            }, { root: true })

        })
    },
    loadPatientForm({ state, commit, rootState }) {
        patients.get(state.selectedPatient.id, rootState.axios).then(result => {
            commit('initPatientForms', result.data.patient)
        }).catch(reason => {
            commit('notifications/showError', {
                title: errors.GET_PATIENT_LOADING_ERROR, content: reason
            }, { root: true })

        })
    },
    updateContactInfos({ state, commit, rootState }) {

        return new Promise((resolve, reject) => {
            patients.updateContactInfos(state.selectedPatient.id, state.contactForm, rootState.axios).then(result => {
                commit('setSelectedPatient', result.data.patient)
                commit('notifications/showSuccess', {
                    title: success.UPDATE_PATIENT_CONTACT_INFOS_SUCCESS.title, content: success.UPDATE_PATIENT_CONTACT_INFOS_SUCCESS.content
                }, { root: true })
                resolve(result)
            }).catch(reason => {
                commit('notifications/showError', {
                    title: errors.UPDATE_PATIENT_CONTACT_INFOS_ERROR, content: reason
                }, { root: true })
                reject(reason)
            })
        });
    },
    updateDoctorsInfos({ state, commit, rootState }) {

        return new Promise((resolve, reject) => {
            patients.updateDoctorsInfos(state.selectedPatient.id, state.doctorsForm, rootState.axios).then(result => {
                commit('setSelectedPatient', result.data.patient)
                commit('notifications/showSuccess', {
                    title: success.UPDATE_PATIENT_DOCTORS_INFOS_SUCCESS.title, content: success.UPDATE_PATIENT_DOCTORS_INFOS_SUCCESS.content
                }, { root: true })
                resolve(result)
            }).catch(reason => {
                commit('notifications/showError', {
                    title: errors.UPDATE_PATIENT_DOCTORS_INFOS_ERROR, content: reason
                }, { root: true })
                reject(reason)
            })
        });
    },
    updateFirmInfos({ state, commit, rootState }) {

        return new Promise((resolve, reject) => {
            patients.updateFirmInfos(state.selectedPatient.id, state.firmForm, rootState.axios).then(result => {
                commit('setSelectedPatient', result.data.patient)
                commit('notifications/showSuccess', {
                    title: success.UPDATE_PATIENT_SUCCESS.title, content: success.UPDATE_PATIENT_SUCCESS.content
                }, { root: true })
                resolve(result)
            }).catch(reason => {
                commit('notifications/showError', {
                    title: errors.UPDATE_PATIENT_ERROR, content: reason
                }, { root: true })
                reject(reason)
            })
        });
    },
    updateJobInfos({ state, commit, rootState }) {

        return new Promise((resolve, reject) => {
            patients.updateJobInfos(state.selectedPatient.id, state.jobForm, rootState.axios).then(result => {
                commit('setSelectedPatient', result.data.patient)
                commit('notifications/showSuccess', {
                    title: success.UPDATE_PATIENT_SUCCESS.title, content: success.UPDATE_PATIENT_SUCCESS.content
                }, { root: true })
                resolve(result)
            }).catch(reason => {
                commit('notifications/showError', {
                    title: errors.UPDATE_PATIENT_ERROR, content: reason
                }, { root: true })
                reject(reason)
            })
        });
    },
    updateTegnerInfos({ state, commit, rootState }) {

        return new Promise((resolve, reject) => {
            patients.updateTegnerInfos(state.selectedPatient.id, state.tegnerForm, rootState.axios).then(result => {
                commit('setSelectedPatient', result.data.patient)
                commit('notifications/showSuccess', {
                    title: success.UPDATE_PATIENT_SUCCESS.title, content: success.UPDATE_PATIENT_SUCCESS.content
                }, { root: true })
                resolve(result)
            }).catch(reason => {
                commit('notifications/showError', {
                    title: errors.UPDATE_PATIENT_ERROR, content: reason
                }, { root: true })
                reject(reason)
            })
        });
    },
}

// mutations
const mutations = {
    setPatients (state, patients) {
        state.all = patients
    },
    setTegner (state, value) {
      state.tegnerScore = value
    },
    setFormGender (state, value) {
        state.form.gender = value
    },
    setFormBirthDate (state, value) {
        state.form.birth_date = value
    },
    setFormFirstname (state, value) {
        state.form.firstname = value
    },
    setFormLastname (state, value) {
        state.form.lastname = value
    },
    setFormEmail (state, value) {
        state.contactForm.email = value
    },
    setFormPhone (state, value) {
        state.contactForm.phone = value
    },
    setFormAddress (state, value) {
        state.contactForm.address = value
    },
    setFormPostcode (state, value) {
        state.contactForm.postcode = value
    },
    setFormCity (state, value) {
        state.contactForm.city = value
    },
    setFormCountry (state, value) {
        state.contactForm.country = value
    },
    setFormMainDoctorName (state, value) {
        state.doctorsForm.main.name = value
    },
    setFormPhysioName (state, value) {
        state.doctorsForm.physio.name = value
    },
    setFormOtherDoctorType (state, {index, data}) {
        state.doctorsForm.others[index].type = data
    },
    setFormOtherDoctorName (state, {index, data}) {
        state.doctorsForm.others[index].name = data
    },
    setFormOtherDoctorLabel (state, {index, data}) {
        state.doctorsForm.others[index].label = data
    },
    addNewEmptyDoctor (state) {
        state.doctorsForm.others.push({name: '', type: "OTHER", label: null})
    },
    setFormSelectFirm (state, value) {
        state.firmForm.firm_select = value
    },
    setFormSelectFirmOther (state, value) {
        state.firmForm.firm_select_other = value
    },
    setFormJob (state, value) {
        state.jobForm.job = value
    },
    setFormTegner (state, value) {
        state.tegnerForm.tegner = value
    },
    removeDoctorAtIndex (state, indexToRemove) {
        state.doctorsForm.others.splice(indexToRemove, 1)
    },
    setSelectedPatient(state, value) {
        state.selectedPatient = value
    },
    setSelectedPatientId(state, value) {
        state.selectedPatient.id = value
    },
    initPatientForms(state, data) {
        state.form.gender = data.gender
        state.form.firstname = data.firstname
        state.form.lastname = data.lastname
        state.form.birth_date = data.birth_date
        state.contactForm.email = data.email
        state.contactForm.phone = data.phone
        state.contactForm.address = data.address
        state.contactForm.city = data.city
        state.contactForm.country = data.country
        state.contactForm.postcode = data.postcode
        state.doctorsForm.main = data.main_doctor ? data.main_doctor : {'name' : ''}
        state.doctorsForm.physio = data.physio_doctor ? data.physio_doctor : {'name' : ''}
        state.doctorsForm.others = data.other_doctors.length > 0 ? data.other_doctors : [{'name' : '', 'type': "OTHER"}]
        state.firmForm.firm_select = data.firm_select
        state.firmForm.firm_select_other = data.firm_select_other
        state.jobForm.job = data.job
        state.tegnerForm.tegner = data.tegner
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
