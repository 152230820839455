export default {
    getPatients(axios) {
        return axios({
            url: `patients`,
            method: 'GET'
        })
    },
    getTegner(axios) {
        return axios({
            url: `tegner`,
            method: 'GET'
        })
    },
    add(patientData, axios) {
        return axios({
            url: `patients/add`,
            method: 'POST',
            data: patientData
        })
    },
    update(id, patientData, axios) {
        return axios({
            url: `patients/${id}/update`,
            method: 'POST',
            data: patientData
        })
    },
    updateContactInfos(id, contactData, axios) {
        return axios({
            url: `patients/${id}/updateContact`,
            method: 'POST',
            data: contactData
        })
    },
    updateDoctorsInfos(id, doctorsData, axios) {
        return axios({
            url: `patients/${id}/updateDoctors`,
            method: 'POST',
            data: doctorsData
        })
    },
    updateFirmInfos(id, infoData, axios) {
        return axios({
            url: `patients/${id}/updateSelectFirm`,
            method: 'POST',
            data: infoData
        })
    },
    updateJobInfos(id, infoData, axios) {
        return axios({
            url: `patients/${id}/updateJob`,
            method: 'POST',
            data: infoData
        })
    },
    updateTegnerInfos(id, infoData, axios) {
        return axios({
            url: `patients/${id}/updateTegner`,
            method: 'POST',
            data: infoData
        })
    },
    get(id, axios) {
        return axios({
            url: `patients/${id}/view`,
            method: 'GET'
        })
    }
}
