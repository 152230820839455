import PatientDetails from '../../../components/patients/PatientDetails'
import PatientForm from '../../../components/patients/PatientForm'
import MedicalCaseForm from "../../../components/patients/medicalCases/MedicalCaseForm";
import MedicalCaseDetails from "../../../components/patients/medicalCases/MedicalCaseDetails";
import TreatmentView from "../../../components/patients/treatment/TreatmentView";
import FollowUpView from "../../../components/patients/followup/FollowUpView";

export default [
    {
        path: '',
        name: 'patient.view',
        component: PatientDetails
    },
    {
        path: 'medical_cases/edit/:medical_case_id',
        name: 'patient.addMedicalCase',
        component: MedicalCaseForm
    },
    {
        path: 'medical_cases/view/:medical_case_id',
        name: 'patient.viewMedicalCase',
        component: MedicalCaseDetails
    },
    {
        path: 'medical_cases/view/:medical_case_id/treatment/:treatment_id',
        name: 'patient.viewTreatment',
        component: TreatmentView
    },
    {
        path: 'patient/view/:patient_id/medical_cases/view/:medical_case_id/treatment/:treatment_id/follow_up/:follow_up_id/view',
        name: 'patient.viewFollowUp',
        component: FollowUpView,
    },
    {
        path: 'edit',
        name: 'patient.edit',
        component: PatientForm,
        props: { mode: 'edit' }
    }
]
