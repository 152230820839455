<template>
    <v-layout column>
        <navigation>
            <template v-slot:title>
                Administration
            </template>
        </navigation>

        ADMIN DASHBOARD - TO BE IMPLEMENTED
    </v-layout>
</template>

<script>
    import Navigation from '../../components/Navigation'
    export default {
        name: "Dashboard",
        components: {
            'navigation' : Navigation
        }
    }
</script>

<style scoped>

</style>
