<template>
    <v-layout row fill-height align-start justify-center>
        <v-flex md12 lg8>
            <v-card tile flat>
                <v-container fluid grid-list-xs>
                    <v-layout row>
                        <v-flex grow>
                            <h3>Diagnostic : {{ getText(resultFollowUp.follow_up.treatment.medical_case.diagnostic.type) }}</h3>

                            <h3>Traitement : {{ getText(resultFollowUp.follow_up.treatment.type) }}</h3>
                            <h4 class="mt-2">Suivi : {{ resultFollowUp.follow_up.follow_up_definition.name }}</h4>
                            <p>Terminé le : {{ resultFollowUp.follow_up.updated_at | shortDate }}</p>

                        </v-flex>
                        <v-flex shrink>
                            <v-btn @click="exportFollowUP" color="primary">Export</v-btn>
                        </v-flex>

                    </v-layout>
                    <v-expansion-panel light>
                        <v-expansion-panel-content row v-for="followUp in resultFollowUp.follow_up.follow_up_scores" :key="followUp.id">
                            <template v-slot:header>
                                <v-flex xs6>
                                    <h2 v-html="followUp.score.name"></h2>
                                </v-flex>
                                <v-flex xs6>
                                    <div>Résultat : <b>{{formatScore(followUp.value, followUp.score.rule)}}</b></div>
                                </v-flex>
                            </template>
                            <v-card>
                                <v-card-text>
                                <div v-for="group in followUp.groups" :key="group.score_group.id">
                                    <div v-if="followUp.groups.length > 1">
                                        <h3>{{ group.score_group.name}}</h3>
                                        <p>Résultat : {{formatScore(group.value, group.score_group.rule)}}</p>
                                    </div>
                                    <v-divider v-if="followUp.groups.length > 1" class="mb-4"></v-divider>
                                    <div v-for="details in getQuestionForGroup(followUp.details, group.score_group.id)"
                                         :key="details.id">
                                        <h5 v-html="details.score_question.text"></h5>
                                        <p>{{details.score_question_answer.text }}{{ details.score_question_answer.remarks ? (' - ' + details.score_question_answer.remarks) : '' }}</p>
                                    </div>
                                </div>
                                </v-card-text>
                            </v-card>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-container>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
    import {mapActions, mapState, mapMutations} from 'vuex'
    import {getTranslationString} from "../../../helper";

    export default {
        name: "FollowUpView",

        created() {
            this.setSelectedPatientId(this.$route.params.patient_id)
            this.setSelectedMedicalCaseId(this.$route.params.medical_case_id)
            this.setSelectedTreatmentId(this.$route.params.treatment_id)
            this.setSelectedFollowUpId(this.$route.params.follow_up_id)
            this.viewResult().then(response => {

            }).catch(reason => {
                //Stay here..
            })
        },
        mounted() {
            console.log(this.resultFollowUp);
        },
        computed: {
            ...mapState('follow_ups', {
                resultFollowUp: state => state.resultFollowUp,
            })

        },
        methods: {
            ...mapMutations('medical_cases', [
                'setSelectedMedicalCaseId'
            ]),
            ...mapMutations('treatments', [
                'setSelectedTreatmentId'
            ]),
            ...mapMutations('patients', [
                'setSelectedPatientId'
            ]),
            ...mapMutations('follow_ups', [
                'setSelectedFollowUpId'
            ]),
            ...mapActions('follow_ups', [
                'viewResult', 'exportResult'
            ]),
            formatScore(score, rule) {
                if (rule === 'sum') {
                    return score.toFixed(2)
                } else {
                    return score.toFixed(2) + " % "
                }
            },
            getQuestionForGroup(questionsDetails, groupId) {
                let results = [];
                for (let i = 0; i < questionsDetails.length; i++) {
                    let detail = questionsDetails[i];
                    if (detail.score_question.score_group_id === groupId) {
                        results.push(detail);
                    }
                }
                return results;
            },
            getText(key) {
                return getTranslationString(key)
            },
            exportFollowUP() {
                this.exportResult().then(response => {
                    let fileName = response.headers["content-disposition"].split("filename=")[1];
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE variant
                        window.navigator.msSaveOrOpenBlob(new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}),
                            fileName);
                    } else {
                        const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', response.headers["content-disposition"].split("filename=")[1]);
                        document.body.appendChild(link);
                        link.click();
                    }
                }).catch(reason => {
                    //Stay here..
                })
            }
        }
    }
</script>

<style scoped>
    .height-max {
        height: 100%;
        overflow: hidden;
    }


</style>
