import Home from '../../pages/default/Home'
import PatientList from '../../components/patients/PatientList'
import PatientView from '../../components/patients/PatientView'
import PatientForm from '../../components/patients/PatientForm'

import patientRouter from './patient/patient'
import ScoreView from "../../components/patients/score/ScoreView";

export default {
    path: '/',
    component: Home,
    meta: {
        auth: {roles: ['USER', 'ADMIN'], redirect: {name: 'login'}, forbiddenRedirect: '/403'}
    },
    children: [
        {
            path: '',
            name: 'home',
            component: PatientList
        },
        {
            path: 'patient/view/:patient_id/medical_cases/view/:medical_case_id/treatment/:treatment_id/follow_up/:follow_up_id',
            name: 'patient.openFollowUp',
            component: ScoreView,
            props: { locked: false }
        },
        {
            path: '/patient/view/:id',
            component: PatientView,
            children: patientRouter
        },
        {
            path: '/patient/add',
            name: 'patient.add',
            component: PatientForm,
            props: { locked: false }
        },

    ]
}
