<template>
    <v-layout row justify-end>

        <v-dialog v-model="dialog" persistent max-width="480">
            <template v-slot:activator="{ on }">
                <v-btn
                    v-on="on"
                    color="primary">
                    Fermer le cas
                </v-btn>
            </template>
            <v-card>
                <v-form ref="closeCaseForm" lazy-validation>
                    <v-card-title class="headline">Fermer le traitement</v-card-title>
                    <v-card-text>

                        <v-container grid-list-md>
                            <v-layout wrap>
                                <v-flex xs12>
                                    <h4>Etes-vous sûr de vouloir fermer ce cas ?</h4>
                                    <v-textarea v-model="remarks" label="Remarques"></v-textarea>
                                </v-flex>
                                <v-flex xs12>

                                    <v-menu v-model="showDatePicker"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            full-width
                                            max-width="290px"
                                            min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                v-model="computedDateFormatted"
                                                label="Date de décès"
                                                append-icon="event"
                                                readonly
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker v-model="death_date" no-title @input="showDatePicker = false"></v-date-picker>
                                    </v-menu>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="secondary" @click="dialog = false">Annuler</v-btn>
                        <v-btn color="primary" @click="save">Oui, fermer</v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </v-layout>
</template>

<script>
    import {mapActions, mapState, mapMutations} from 'vuex'

    export default {
        name: "CaseCloseForm",
        data() {
            return {
                showDatePicker: false,
                dialog: false,
                remarks: '',
                death_date: ''
            }
        },
        computed: {
            computedDateFormatted: {
                get() {
                    return this.death_date ? this.$options.filters.formatedDate(this.death_date) : ''
                }
            },
            ...mapState('medical_cases', {
                selectedMedicalCase: state => state.selectedMedicalCase,
            }),
            ...mapState('patients', {
                selectedPatient: state => state.selectedPatient,
            }),
        },
        methods: {
            save() {
                this.dialog = false;
                if (this.selectedMedicalCase && this.$refs.closeCaseForm.validate()) {
                    this.closeMedicalCase({remarks: this.remarks, date_of_death: this.death_date}).then(response => {
                        this.$router.push({name: 'patient.view', params: {id: this.selectedPatient.id}})
                    }).catch(reason => {
                        //Stay here..
                    })
                }
            },
            ...mapActions('medical_cases', [
                'closeMedicalCase'
            ]),
        }
    }
</script>
