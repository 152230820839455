var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-form',{ref:"treatmentForm",attrs:{"lazy-validation":""}},[_c('v-card-title',[(this.id == null)?_c('span',{staticClass:"headline"},[_vm._v("Ajouter un traitement")]):_c('span',{staticClass:"headline"},[_vm._v("Traitement")])]),_vm._v(" "),_c('v-card-text',[_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[(this.id == null)?_c('v-select',{attrs:{"value":_vm.type,"rules":[function (v) { return !!v || 'Item is required'; }],"items":[
                            {text: _vm.getText('TREATMENT_FULL_KNEE_REPLACEMENT'), value: 'TREATMENT_FULL_KNEE_REPLACEMENT'},
                            {text: _vm.getText('TREATMENT_FULL_HIP_REPLACEMENT'), value: 'TREATMENT_FULL_HIP_REPLACEMENT'},
                            {text: _vm.getText('TREATMENT_INF_CORTISONE'), value: 'TREATMENT_INF_CORTISONE'},
                            {text: _vm.getText('TREATMENT_INF_ACIDE_HYALURONIQUE'), value: 'TREATMENT_INF_ACIDE_HYALURONIQUE'},
                            {text: _vm.getText('TREATMENT_SURGERY'), value: 'TREATMENT_SURGERY'},
                            {text: _vm.getText('TREATMENT_PHYSIO'), value: 'TREATMENT_PHYSIO'},
                            {text: _vm.getText('TREATMENT_OSTEO'), value: 'TREATMENT_OSTEO'},
                            {text: _vm.getText('TREATMENT_OTHER'), value: 'TREATMENT_OTHER'} ],"label":"Type","required":""},on:{"change":function (value) { _vm.setType(value) }}}):_c('p',[_c('span',{staticClass:"subheading"},[_vm._v("Type :")]),_vm._v(" "+_vm._s(_vm.getText(_vm.type))+" ")])],1),_vm._v(" "),_c('v-flex',{attrs:{"xs12":""}},[_c('v-textarea',{attrs:{"label":"Description","required":""},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1)],1)],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"secondary"},on:{"click":_vm.close}},[_vm._v("Annuler")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.save}},[_vm._v("Enregistrer")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }