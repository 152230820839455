import diagnostics from '../../api/diagnostics'

const success = {
    CREATE_DIAGNOSTIC_SUCCESS: {
        title: "Succès",
        content: "Diagnostic créé avec succès"
    },
    UPDATE_DIAGNOSTIC_SUCCESS: {
        title: "Succès",
        content: "Diagnostic mis à jour"
    },
}
const errors = {
    CREATE_DIAGNOSTIC_ERROR: 'Erreur lors de la création du diagnostic, merci de réessayer',
    UPDATE_DIAGNOSTIC_ERROR: 'Erreur lors de la mise à jour du diagnostic, merci de réessayer',
}

// initial state
const state = {
    form: {
        type: null,
        description: null
    },
    showModal: false
}

// getters
const getters = {}

// actions
const actions = {

    saveDiagnostic({ state, commit, rootState }) {
        return new Promise((resolve, reject) => {
            if (state.form.id == null) {
                diagnostics.add(rootState.patients.selectedPatient.id, rootState.medical_cases.selectedMedicalCase.id, state.form, rootState.axios).then(result => {
                    commit('notifications/showSuccess', {
                        title: success.CREATE_DIAGNOSTIC_SUCCESS.title,
                        content: success.CREATE_DIAGNOSTIC_SUCCESS.content
                    }, {root: true})
                    resolve(result)
                }).catch(reason => {
                    commit('notifications/showError', {
                        title: errors.CREATE_DIAGNOSTIC_ERROR, content: reason
                    }, {root: true})
                    reject(reason)
                })
            } else {
                diagnostics.update(rootState.patients.selectedPatient.id, rootState.medical_cases.selectedMedicalCase.id, state.form.id, state.form, rootState.axios).then(result => {
                    commit('notifications/showSuccess', {
                        title: success.UPDATE_DIAGNOSTIC_SUCCESS.title, content: success.UPDATE_DIAGNOSTIC_SUCCESS.content
                    }, { root: true })
                    resolve(result)
                }).catch(reason => {
                    commit('notifications/showError', {
                        title: errors.UPDATE_DIAGNOSTIC_ERROR, content: reason
                    }, { root: true })
                    reject(reason)
                })
            }
        })
    }
}

// mutations
const mutations = {
    setFormType (state, value) {
        state.form.type = value
    },
    setFormDescription (state, value) {
        state.form.description = value
    },
    initForm (state, value) {
        if (value != null) {
            state.form.id = value.id
            state.form.type = value.type
            state.form.description = value.description
        } else {
            state.form.id = null
            state.form.type = null
            state.form.description = null
        }
    },
    setShowModal (state, value) {
        state.showModal = value
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
