export function uuid () {
    let dt = new Date().getTime()
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = (dt + Math.random() * 16) % 16 | 0
        dt = Math.floor(dt / 16)
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
    })
}

const TRANSLATION_TEXTS = {
    "fr" : {
        ARTICULATION_LEFT_KNEE: "Genou gauche",
        ARTICULATION_RIGHT_KNEE: "Genou droit",
        ARTICULATION_BOTH_KNEE: "Les deux genoux",
        ARTICULATION_LEFT_HIP: "Hanche gauche",
        ARTICULATION_RIGHT_HIP: "Hanche droite",
        ARTICULATION_UPPER_LIMB: "Membre supérieur",

        DISEASE_DIABETES: "Diabète",
        DISEASE_HYPERTENSION: "Hypertension",
        DISEASE_CHOLESTEROL: "Hypercholestérolémie",
        DISEASE_ARTHRITIS: "Polyarthrite",
        DISEASE_FIBRILLATION: "Trouble du rythme cardiaque (Fibrillation, autre)",
        DISEASE_COAGULATION_DISORDER: "Trouble de la coagulation",
        DISEASE_NONE: "Aucune",

        SINCE_ACCIDENT: "Depuis l’accident",
        SINCE_LESS_THAN_A_MONTH: "Depuis moins de 1 mois",
        SINCE_MORE_THAN_A_MONTH: "Depuis plus de 1 mois",
        SINCE_SIX_MONTH: "Depuis plus que 6 mois",
        SINCE_ONE_YEAR: "Depuis plus que 1 an",
        SINCE_TWO_YEARS: "Depuis plus que 2 ans",
        SINCE_FIVE_YEARS: "Depuis plus que 5 ans",
        SINCE_TEN_YEARS: "Depuis plus que 10 ans",

        male: "Homme",
        female: "Femme",
        undefined: "Indéfini",
        other: "Autre",

        TREATMENT_FULL_KNEE_REPLACEMENT: "Prothèse totale du genou",
        TREATMENT_FULL_HIP_REPLACEMENT: "Prothèse de la hanche",
        TREATMENT_SURGERY: "Chirurgie",
        TREATMENT_PHYSIO: "Physio",
        TREATMENT_OSTEO: "Ostéopathie",
        TREATMENT_INF_CORTISONE: "Infiltration de Cortisone",
        TREATMENT_INF_ACIDE_HYALURONIQUE: "Infiltration d'acide hyaluronique",
        TREATMENT_OTHER: "Autre traitement",

        DIAGNOSTIC_GONARTHROSE: "Gonarthrose",
        DIAGNOSTIC_ARTHROSE_HANCHE: "Arthrose de hanche",
        DIAGNOSTIC_LCA: "Rupture LCA",
        DIAGNOSTIC_LCP: "Rupture LCP",
        DIAGNOSTIC_LPI: "Lésion plan interne (LLI, POL)",
        DIAGNOSTIC_LPE: "Lésion plan externe (LLE, poplité, PFL)",
        DIAGNOSTIC_LM: "Lésions méniscales",
        DIAGNOSTIC_CARTI_NON_DEG: "Lésions cartilagineuses non dégénératives",
        DIAGNOSTIC_ROTULE: "Instabilité rotule",
        DIAGNOSTIC_LESION_CARTI_ROTULE: "Lésion cartilage rotule/trochlée",
        DIAGNOSTIC_OTHER: "Autres",

        FOLLOW_UP_STATUS_SKIP: "Passé",
        FOLLOW_UP_STATUS_COMPLETED: "Terminé",
        FOLLOW_UP_STATUS_UNCOMPLETED: "En cours",
        FOLLOW_UP_STATUS_NEXT: "Prévu",

        doctor: "Via médecin",
        physio: "Via physio",
        internet: "Via internet",
        reception: "Via réception de La Tour",
        emergencies: "Via urgences de La Tour",
        person_advise: "Via conseil d’une personne",

        desk: "Bureau",
        admin_manual: "Administratif et Manuel",
        manual_light: "Manuel léger",
        manual_heavy: "Manuel lourd",


    }
}
export function getTranslationString(key, lang = "fr") {
    return TRANSLATION_TEXTS[lang][key]
}
