import VueRouter from "vue-router";
import Vue from "vue";

Vue.use(VueRouter)

// Pages
import Login from '../pages/Login'
import LockScreen from '../pages/LockScreen'
import admin from './modules/admin'
import dashboard from './modules/dashboard'
import store from '../store'

// Routes
const routes = [
    dashboard,
    admin,
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            auth: false
        }
    },
    {
        path: '/locked',
        name: 'lock-screen',
        component: LockScreen,
        meta: {
            auth: true
        }
    },
    {
        path: '*',
        redirect: '/'
    }
]

const router = new VueRouter({
    history: true,
    mode: 'history',
    routes,
})

router.beforeEach((to, from, next) => {
    store.dispatch('fetchLockedState');

    if(store.state.locked === "true" && to.name !== 'lock-screen' && to.name !== 'login') {
        next({ name: 'lock-screen'})
    }
    else if (store.state.locked !== "true" && to.name === 'lock-screen') {
        next({ name: 'home'})
    }
    else next()
})

export default router
