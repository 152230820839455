export default {
    add(id, caseId, treatmentData, axios) {
        return axios({
            url: `patients/${id}/medicalCase/${caseId}/treatment/add`,
            method: 'POST',
            data: treatmentData
        })
    },
    update(id, caseId, treatmentId, treatmentData, axios) {
        return axios({
            url: `patients/${id}/medicalCase/${caseId}/treatment/${treatmentId}/update`,
            method: 'POST',
            data: treatmentData
        })
    },
    close(id, caseId, treatmentId, remarksData, axios) {
        return axios({
            url: `patients/${id}/medicalCase/${caseId}/treatment/${treatmentId}/close`,
            method: 'POST',
            data: remarksData
        })
    },
    get(id, caseId, treatmentId, axios) {
        return axios({
            url: `patients/${id}/medicalCase/${caseId}/treatment/${treatmentId}/view`,
            method: 'GET'
        })
    },
}
