<template>
    <v-card>
        <v-form ref="treatmentForm" lazy-validation>
            <v-card-title>
                <span class="headline" v-if="this.id == null">Ajouter un traitement</span>
                <span class="headline" v-else>Traitement</span>
            </v-card-title>
            <v-card-text>
                <v-container grid-list-md>
                    <v-layout wrap>
                        <v-flex xs12>
                            <v-select
                                v-if="this.id == null"
                                :value="type"
                                :rules="[v => !!v || 'Item is required']"
                                :items="[
                                {text: getText('TREATMENT_FULL_KNEE_REPLACEMENT'), value: 'TREATMENT_FULL_KNEE_REPLACEMENT'},
                                {text: getText('TREATMENT_FULL_HIP_REPLACEMENT'), value: 'TREATMENT_FULL_HIP_REPLACEMENT'},
                                {text: getText('TREATMENT_INF_CORTISONE'), value: 'TREATMENT_INF_CORTISONE'},
                                {text: getText('TREATMENT_INF_ACIDE_HYALURONIQUE'), value: 'TREATMENT_INF_ACIDE_HYALURONIQUE'},
                                {text: getText('TREATMENT_SURGERY'), value: 'TREATMENT_SURGERY'},
                                {text: getText('TREATMENT_PHYSIO'), value: 'TREATMENT_PHYSIO'},
                                {text: getText('TREATMENT_OSTEO'), value: 'TREATMENT_OSTEO'},
                                {text: getText('TREATMENT_OTHER'), value: 'TREATMENT_OTHER'},
                            ]"
                                @change="(value) => { setType(value) }"
                                label="Type"
                                required
                            ></v-select>
                            <p v-else><span class="subheading">Type :</span> {{ getText(type) }} </p>
                        </v-flex>
                        <v-flex xs12>
                            <v-textarea v-model="description" label="Description" required></v-textarea>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" @click="close">Annuler</v-btn>
                <v-btn color="primary" @click="save">Enregistrer</v-btn>
            </v-card-actions>
        </v-form>
    </v-card>
</template>

<script>
    import {mapActions, mapState, mapMutations} from 'vuex'
    import {getTranslationString} from "../../../helper";

    export default {
        name: "TreatmentForm",
        computed: {
            type: {
                get() {
                    return this.$store.state.treatments.form.type
                },
                set(value) {
                    this.setType(value)
                }
            },
            description: {
                get() {
                    return this.$store.state.treatments.form.description
                },
                set(value) {
                    this.setDescription(value)
                }
            },
            id: {
                get() {
                    return this.$store.state.treatments.form.id
                },
                set(value) {
                    this.setId(id)
                }
            }
        },
        methods: {
            save() {
                this.$store.state.treatments.form.id == null ? this.create() : this.update();
            },
            create() {
                if (this.$refs.treatmentForm.validate()) {
                    this.createTreatment().then(response => {
                        this.getMedicalCaseInfo();
                        this.setShowForm(false);
                    }).catch(reason => {
                        //Stay here..
                    })
                }
            },
            close() {
                this.setShowForm(false)
            },
            update() {
                this.updateTreatment().then(response => {
                    this.getTreatmentInfo();
                    this.setShowForm(false);
                }).catch(reason => {
                    //Stay here..
                })
            },
            getText(key) {
                return getTranslationString(key)
            },
            ...mapMutations('treatments', {
                setType: 'setFormType',
                setDescription: 'setFormDescription',
                setId: 'setFormId',
                setShowForm: 'setShowForm',
            }),
            ...mapActions('treatments', ['createTreatment', 'updateTreatment', 'getTreatmentInfo']),
            ...mapActions('medical_cases', ['getMedicalCaseInfo'])
        }
    }
</script>
