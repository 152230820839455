<template>
    <v-card>
        <v-form ref="diagnosticForm" lazy-validation>
            <v-card-title>
                <span class="headline">Diagnostique</span>
            </v-card-title>
            <v-card-text>
                <v-container grid-list-md>


                    <v-layout wrap>
                        <v-flex xs12>
                            <v-select v-if="isEditable()"
                                      :value="type"
                                      :rules="[v => !!v || 'Item is required']"
                                      :items="[
                                {text: getText('DIAGNOSTIC_GONARTHROSE'), value: 'DIAGNOSTIC_GONARTHROSE'},
                                {text: getText('DIAGNOSTIC_ARTHROSE_HANCHE'), value: 'DIAGNOSTIC_ARTHROSE_HANCHE'},
                                {text: getText('DIAGNOSTIC_LCA'), value: 'DIAGNOSTIC_LCA'},
                                {text: getText('DIAGNOSTIC_LCP'), value: 'DIAGNOSTIC_LCP'},
                                {text: getText('DIAGNOSTIC_LPI'), value: 'DIAGNOSTIC_LPI'},
                                {text: getText('DIAGNOSTIC_LPE'), value: 'DIAGNOSTIC_LPE'},
                                {text: getText('DIAGNOSTIC_LM'), value: 'DIAGNOSTIC_LM'},
                                {text: getText('DIAGNOSTIC_CARTI_NON_DEG'), value: 'DIAGNOSTIC_CARTI_NON_DEG'},
                                {text: getText('DIAGNOSTIC_ROTULE'), value: 'DIAGNOSTIC_ROTULE'},
                                {text: getText('DIAGNOSTIC_LESION_CARTI_ROTULE'), value: 'DIAGNOSTIC_LESION_CARTI_ROTULE'},
                                {text: getText('DIAGNOSTIC_OTHER'), value: 'DIAGNOSTIC_OTHER'},
                            ]"
                                      @change="(value) => { setType(value) }"
                                      label="Type"
                                      required
                            ></v-select>
                            <p v-else><span class="subheading">Type :</span> {{ getText(type) }} </p>
                        </v-flex>
                        <v-flex xs12>
                            <v-textarea v-model="description" label="Description" required></v-textarea>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" @click="close">Annuler</v-btn>
                <v-btn color="primary" @click="validate">Enregistrer</v-btn>
            </v-card-actions>
        </v-form>
    </v-card>
</template>

<script>
    import {mapActions, mapState, mapMutations} from 'vuex'
    import {getTranslationString} from "../../../helper";

    export default {
        name: "DiagnosticForm",
        mounted() {
            this.$refs.diagnosticForm.reset()
        },
        data: () => ({
            editable: false,
        }),
        computed: {

            type: {
                get() {
                    return this.$store.state.diagnostics.form.type
                },
                set(value) {
                    this.setType(value)
                }
            },
            description: {
                get() {
                    return this.$store.state.diagnostics.form.description
                },
                set(value) {
                    this.setDescription(value)
                }
            },
            ...mapState('diagnostics', {
                diagnosticId: state => state.form.id,
            }),
            ...mapState('medical_cases', {
                selectedMedicalCase: state => state.selectedMedicalCase
            })
        },
        methods: {
            validate() {
                if (this.editable) {
                    if (this.$refs.diagnosticForm.validate()) {
                        this.save()
                    }
                } else {
                    this.save()
                }
            },
            save() {
                this.saveDiagnostic().then(response => {
                    this.showModal(false)
                    this.getMedicalCaseInfo()
                }).catch(reason => {
                    //Stay here..
                })
            },
            close() {
                this.showModal(false)
            },
            getText(key) {
                return getTranslationString(key)
            },
            isEditable() {
                if (this.selectedMedicalCase.treatments) {
                    for (let i = 0; i < this.selectedMedicalCase.treatments.length; i++) {
                        if (this.selectedMedicalCase.treatments[i].follow_ups.length > 0) {
                            this.editable = false;
                            return false;
                        }
                    }
                }
                this.editable = true;
                return true;
            },
            ...mapMutations('diagnostics', {
                setType: 'setFormType',
                setDescription: 'setFormDescription',
                showModal: 'setShowModal',
            }),
            ...mapActions('diagnostics', ['saveDiagnostic']),
            ...mapActions('medical_cases', ['getMedicalCaseInfo'])
        }
    }
</script>
