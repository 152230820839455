<template>
    <v-layout>
        <v-flex>
            <patient-general-infos-card></patient-general-infos-card>

            <v-divider light></v-divider>
            <v-card-text class="px-5">
                <v-flex md12>
                    <medical-case-list-view></medical-case-list-view>
                </v-flex>
            </v-card-text>
        </v-flex>
    </v-layout>
</template>

<script>
    import { mapActions, mapState, mapMutations } from 'vuex'
    import MedicalCaseListView from "./medicalCases/MedicalCaseListView";
    import PatientGeneralInfosCard from "./cards/PatientGeneralInfosCard";

    export default {
        name: "PatientDetails",
        components: {
            'medical-case-list-view' : MedicalCaseListView,
            'patient-general-infos-card' : PatientGeneralInfosCard
        },
        computed: mapState('patients', {
            selectedPatient: state => state.selectedPatient
        }),
    }
</script>

<style scoped>

</style>
