<template>
    <v-layout align-center justify-center>
        <v-flex xs12 sm10 md8>
            <v-card class="elevation-12">
                <v-toolbar dark color="primary">
                    <v-toolbar-title>Connexion</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-form autocomplete="off" @submit.prevent="login">
                <v-card-text>
                    <v-alert v-model="has_error" dismissible type="error">{{ error_message }}</v-alert>
                        <v-text-field prepend-icon="person" name="login" label="Identifiant" type="text" v-model="email" required></v-text-field>
                        <v-text-field id="password" prepend-icon="lock" name="password" label="Mot de passe" type="password" v-model="password" required></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" type="submit">Se connecter</v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
    import { mapMutations } from 'vuex'
    export default {
        name: 'Login',
        data() {
            return {
                email: null,
                password: null,
                has_error: false,
                error_message: String
            }
        },

        mounted() {
        },

        methods: {
            ...mapMutations(['updateLockedState']),
            login() {
                // get the redirect object
                var redirect = this.$auth.redirect()

                this.$auth.login({
                    data: {
                        email: this.email,
                        password: this.password
                    },
                    success: () => {
                        //Disable lock screen if enabled but session no longer valid
                        this.updateLockedState(null)
                        // handle redirection
                        const redirectTo = redirect && redirect.from.name !== 'login' ? redirect.from.name : 'home'
                        this.$router.push({name: redirectTo})
                    },
                    error: (res) => {
                        this.has_error = true
                        this.error_message = res.response.data.error
                    },
                    rememberMe: true,
                    fetchUser: true
                })
            }
        }
    }
</script>

<style scoped>

</style>
