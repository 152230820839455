import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

import patients from './modules/patients'
import notifications from './modules/notifications'
import medical_cases from './modules/medical_cases'
import diagnostics from './modules/diagnostics'
import treatments from './modules/treatments'
import follow_ups from './modules/follow_ups'
import userAPI from '../api/users'

export default new Vuex.Store({
    modules: {
        patients,
        notifications,
        medical_cases,
        diagnostics,
        treatments,
        follow_ups
    },
    state: {
        locked: null,
        axios: null,
        back: null,
    },
    actions: {
        fetchLockedState({commit}) {
            commit('updateLockedState', localStorage.getItem('lockedState'));
        },
        unlock({state, commit}, {code}) {
            return new Promise((resolve, reject) => {
                userAPI.unlock(code, state.axios).then(result => {
                    commit('updateLockedState', null);
                    resolve(result)
                }).catch(reason => {
                    reject(reason)
                })
            });
        },
    },
    mutations: {
        updateLockedState: (state, lockedState) => {
            localStorage.setItem('lockedState', lockedState);
            state.locked = lockedState;
        },
        setLockedBack: (state, back) => {
            state.back = back;
        },
        setAxios: (state, axios) => {
            state.axios = axios;
        }
    },
    strict: debug,
    plugins: debug ? [createLogger()] : []
})
