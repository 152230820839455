<template>
    <v-app>
        <v-content>
            <v-container fill-height>
                <router-view></router-view>
            </v-container>
        </v-content>

        <v-alert global :key="notification.id"
                 v-for="notification in errorNotifications"
                 dismissible
                 @input="closeError(notification.id)"
                 :value="true" type="error">
            <b>{{ notification.message.title }}: </b>{{ notification.message.content }}
        </v-alert>
        <v-alert global :key="notification.id"
                 v-for="notification in infoNotifications"
                 dismissible
                 @input="closeInfo(notification.id)"
                 :value="true" type="info">
            <b>{{ notification.message.title }}: </b>{{ notification.message.content }}
        </v-alert>
        <v-alert v-if="false" global :key="notification.id"
                 v-for="notification in successNotifications"
                 dismissible
                 @input="closeSuccess(notification.id)"
                 :value="true" type="success">
            <b>{{ notification.message.title }}: </b>{{ notification.message.content }}
        </v-alert>
    </v-app>
</template>

<script>
    import { mapState, mapMutations, mapActions } from 'vuex'

    export default {
        name: "App",
        data: () => ({
            title: window.appName,
            drawer: false,
            willClose: []
        }),
        computed: mapState({
            errorNotifications: state => state.notifications.errorNotifications,
            infoNotifications: state => state.notifications.infoNotifications,
            successNotifications: state => state.notifications.successNotifications
        }),
        methods: {
            ...mapMutations({
                closeError: 'notifications/closeError',
                closeInfo: 'notifications/closeInfo',
                closeSuccess: 'notifications/closeSuccess'
            })
        },
        updated() {
            for (let notif of this.successNotifications) {
                if (this.willClose.indexOf(notif.id) === -1) {
                    setTimeout(() => {
                        this.closeSuccess(notif.id);
                        this.willClose.splice(this.willClose.indexOf(notif.id), 1);
                    }, 1000);

                    this.willClose.push(notif.id)
                }
            }

            for (let notif of this.infoNotifications) {
                if (this.willClose.indexOf(notif.id) === -1) {
                    setTimeout(() => {
                        this.closeInfo(notif.id);
                        this.willClose.splice(this.willClose.indexOf(notif.id), 1);
                    }, 1000);

                    this.willClose.push(notif.id)
                }
            }

            for (let notif of this.errorNotifications) {
                if (this.willClose.indexOf(notif.id) === -1) {
                    setTimeout(() => {
                        this.closeError(notif.id);
                        this.willClose.splice(this.willClose.indexOf(notif.id), 1);
                    }, 5000);

                    this.willClose.push(notif.id)
                }
            }
        }
    }
</script>
