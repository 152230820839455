<template>
    <v-card-text>
        <v-container grid-list-md pa-0>
            <v-layout row wrap>
                <v-flex md4>
                    <v-list>
                        <v-list-tile v-if="selectedPatient.main_doctor">
                            <v-list-tile-action>
                                <v-icon color="secondary">assignment_ind</v-icon>
                            </v-list-tile-action>
                            <v-list-tile-content v-if="selectedPatient.main_doctor">
                                <v-list-tile-title>Médecin traitant</v-list-tile-title>
                                <v-list-tile-sub-title>{{ selectedPatient.main_doctor.name }}</v-list-tile-sub-title>
                            </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile v-else>
                            <v-list-tile-action>
                                <v-icon color="secondary">assignment_ind</v-icon>
                            </v-list-tile-action>
                            <v-list-tile-content>
                                <v-list-tile-title><b>Aucun médecin traitant connu</b></v-list-tile-title>
                            </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile v-if="selectedPatient.physio_doctor">
                            <v-list-tile-action>
                                <v-icon color="secondary">assignment_ind</v-icon>
                            </v-list-tile-action>
                            <v-list-tile-content v-if="selectedPatient.physio_doctor">
                                <v-list-tile-title>Physio</v-list-tile-title>
                                <v-list-tile-sub-title>{{ selectedPatient.physio_doctor.name }}</v-list-tile-sub-title>
                            </v-list-tile-content>
                        </v-list-tile>
                    </v-list>
                </v-flex>
               <v-flex md3>
                    <v-list>
                        <v-list-tile>
                            <v-list-tile-action>
                                <v-icon color="secondary">phone</v-icon>
                            </v-list-tile-action>
                            <v-list-tile-content>
                                <v-list-tile-title>Téléphone</v-list-tile-title>
                                <v-list-tile-sub-title>{{ selectedPatient.phone ? selectedPatient.phone : "inconnu" }}</v-list-tile-sub-title>
                            </v-list-tile-content>
                        </v-list-tile>
                        <v-list-tile>
                            <v-list-tile-action>
                                <v-icon color="secondary">email</v-icon>
                            </v-list-tile-action>
                            <v-list-tile-content>
                                <v-list-tile-title>Email</v-list-tile-title>
                                <v-list-tile-sub-title>{{ selectedPatient.email ? selectedPatient.email : "inconnu" }}</v-list-tile-sub-title>
                            </v-list-tile-content>
                        </v-list-tile>
                    </v-list>
               </v-flex>
                <v-flex md5>
                    <v-list two-line>
                        <v-list-tile>
                            <v-list-tile-action>
                                <v-icon color="secondary">location_on</v-icon>
                            </v-list-tile-action>
                            <v-list-tile-content v-if="selectedPatient.address">
                                <v-list-tile-title>Adresse</v-list-tile-title>
                                <v-list-tile-sub-title>{{ selectedPatient.address }}</v-list-tile-sub-title>
                                <v-list-tile-sub-title>{{ selectedPatient.postcode }} {{ selectedPatient.city }}, {{ selectedPatient.country }}</v-list-tile-sub-title>
                            </v-list-tile-content>
                            <v-list-tile-content v-else>
                                <v-list-tile-sub-title>Aucune adresse connue</v-list-tile-sub-title>
                            </v-list-tile-content>
                        </v-list-tile>
                    </v-list>
                </v-flex>
            </v-layout>
            <v-layout>
                <v-flex md12 v-if="selectedPatient.other_doctors && selectedPatient.other_doctors.length > 0">
                    <v-list>
                        <v-list-tile>
                            <v-list-tile-action>
                                <v-icon color="secondary">assignment_ind</v-icon>
                            </v-list-tile-action>
                            <v-list-tile-content v-if="selectedPatient.other_doctors.length > 0">
                                <v-list-tile-title><span class="v-list__tile__title">Autres médecins</span>: <span class="v-list__tile__sub-title" v-for="(doctor, index) in selectedPatient.other_doctors" :key="index">{{ doctor.name + " (" + (doctor.label ? doctor.label : doctor.type) + ")" }} </span></v-list-tile-title>
                            </v-list-tile-content>
                        </v-list-tile>
                    </v-list>
                </v-flex>
            </v-layout>
        </v-container>
    </v-card-text>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: "PatientGeneralInfosCard",
        computed: mapState('patients', {
            selectedPatient: state => state.selectedPatient
        }),
    }
</script>

<style scoped>

</style>
