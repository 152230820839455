export default {
    createFollowUp(id, caseId, treatmentId, followUpDefinitionData, axios) {
        return axios({
            url: `patients/${id}/medicalCase/${caseId}/treatment/${treatmentId}/followUp/add`,
            method: 'POST',
            data: followUpDefinitionData
        })
    },
    next(id, caseId, treatmentId, followUpId, axios) {
        return axios({
            url: `patients/${id}/medicalCase/${caseId}/treatment/${treatmentId}/followUp/${followUpId}/next`,
            method: 'GET'
        })
    },
    removeLast(id, caseId, treatmentId, followUpId, axios) {
        return axios({
            url: `patients/${id}/medicalCase/${caseId}/treatment/${treatmentId}/followUp/${followUpId}/removeLast`,
            method: 'GET'
        })
    },
    update(id, caseId, treatmentId, followUpId, answerData, axios) {
        return axios({
            url: `patients/${id}/medicalCase/${caseId}/treatment/${treatmentId}/followUp/${followUpId}/next`,
            method: 'POST',
            data: answerData
        })
    },
    view(id, caseId, treatmentId, followUpId, axios) {
        return axios({
            url: `patients/${id}/medicalCase/${caseId}/treatment/${treatmentId}/followUp/${followUpId}/view`,
            method: 'GET'
        })
    },
    export(id, caseId, treatmentId, followUpId, axios) {
        return axios({
            url: `patients/${id}/medicalCase/${caseId}/treatment/${treatmentId}/followUp/${followUpId}/export`,
            method: 'GET',
            responseType: 'blob'
        })
    },
    fullExport(axios) {
        return axios({
            url: `patients/exportResults`,
            method: 'GET',
            responseType: 'blob'
        })
    }
}
