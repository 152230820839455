<template>
    <v-layout row justify-end>
        <v-dialog v-model="dialog" scrollable max-width="800">
            <template v-slot:activator="{ on }">
                <v-btn
                    v-on="on"
                    flat color="primary">
                    <v-icon left>mdi-clipboard-account</v-icon>
                    Info patient
                </v-btn>
            </template>
            <v-card>

                <v-card-text>
                    <v-container grid-list-md>
                        <v-layout row wrap>
                            <v-flex xs12 mb-3>
                                <div class="headline">{{ selectedPatient.lastname + " " + selectedPatient.firstname }}
                                </div>
                                <div class="grey--text">{{ this.getText(selectedPatient.gender) }} - {{
                                    selectedPatient.birth_date | shortDate }}
                                    ({{
                                    this.age }} ans)
                                </div>
                                <v-divider></v-divider>
                            </v-flex>

                            <v-flex md6>
                                <v-layout row>
                                    <v-flex shrink>
                                        <v-icon color="secondary">local_hospital</v-icon>
                                    </v-flex>
                                    <v-flex grow>
                                        <p><span class="subheading">Médecin traitant</span><br>
                                            <span v-if="selectedPatient.main_doctor" class="grey--text">{{ selectedPatient.main_doctor.name }}</span>
                                            <span v-else class="grey--text">Aucun médecin traitant connu</span>
                                        </p>
                                        <p><span class="subheading">Physio</span><br>
                                            <span v-if="selectedPatient.physio_doctor" class="grey--text">{{ selectedPatient.physio_doctor.name }}</span>
                                            <span v-else class="grey--text">Aucun physio</span>
                                        </p>
                                        <p v-if="selectedPatient.other_doctors && selectedPatient.other_doctors.length > 0">
                                            <span class="subheading">Autres médecins</span><br>
                                            <span v-for="(doctor, index) in selectedPatient.other_doctors" :key="index"
                                                  class="grey--text">{{ doctor.name + " (" + (doctor.label ? doctor.label : doctor.type) + ")" }}<br></span>
                                        </p>
                                    </v-flex>
                                </v-layout>

                                <v-layout row>
                                    <v-flex shrink>
                                        <v-icon color="secondary">mdi-home-city</v-icon>
                                    </v-flex>
                                    <v-flex grow>
                                        <p><span class="subheading">Choix du cabinet</span>
                                            <br><span class="grey--text">{{ selectedPatient.firm_select ? getText(selectedPatient.firm_select) : "Inconnu" }}</span>
                                            <span class="grey--text" v-if="selectedPatient.firm_select_other"><br>{{ selectedPatient.firm_select_other }}</span>
                                        </p>
                                    </v-flex>
                                </v-layout>

                            </v-flex>
                            <v-flex md6>
                                <v-layout row>
                                    <v-flex shrink>
                                        <v-icon color="secondary">phone</v-icon>
                                    </v-flex>
                                    <v-flex grow>
                                        <p><span class="subheading">Téléphone</span><br><span class="grey--text">{{ selectedPatient.phone ? selectedPatient.phone : "Inconnu" }}</span>
                                        </p>
                                    </v-flex>
                                </v-layout>
                                <v-layout row>
                                    <v-flex shrink>
                                        <v-icon color="secondary">email</v-icon>
                                    </v-flex>
                                    <v-flex grow>
                                        <p><span class="subheading">Email</span><br><span class="grey--text">{{ selectedPatient.email ? selectedPatient.email : "Inconnu" }}</span>
                                        </p>
                                    </v-flex>
                                </v-layout>
                                <v-layout row>
                                    <v-flex shrink>
                                        <v-icon color="secondary">location_on</v-icon>
                                    </v-flex>
                                    <v-flex grow>
                                        <p><span class="subheading">Adresse</span><br>
                                            <span v-if="selectedPatient.address" class="grey--text">{{ selectedPatient.address }}
                                        <br>{{ selectedPatient.postcode }} {{ selectedPatient.city }}, {{ selectedPatient.country }}
                                    </span>
                                            <span v-else class="grey--text"> Aucune adresse connue</span><br>
                                        </p>
                                    </v-flex>
                                </v-layout>

                            </v-flex>

                            <v-flex md6>
                                <v-layout row>
                                    <v-flex shrink>
                                        <v-icon color="secondary">mdi-account</v-icon>
                                    </v-flex>
                                    <v-flex grow>
                                        <p><span class="subheading">Travail</span><br><span class="grey--text">{{ selectedPatient.job ? getText(selectedPatient.job) : "Inconnu" }}</span>
                                        </p>
                                    </v-flex>
                                </v-layout>
                            </v-flex>

                            <v-flex md6>
                                <v-layout row>
                                    <v-flex shrink>
                                        <v-icon color="secondary">list_alt</v-icon>
                                    </v-flex>
                                    <v-flex grow>
                                        <p><span class="subheading">Score Tegner</span><br><span class="grey--text">{{ selectedPatient.tegner ? selectedPatient.tegner : "Inconnu" }}</span>
                                        </p>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                        </v-layout>

                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn flat @click="dialog = false" color="primary">Fermer</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-layout>
</template>

<script>
    import {mapState} from 'vuex'
    import {getTranslationString} from "../../../helper"

    export default {
        name: "PatientGeneralInfosModal",
        data () {
            return {
                dialog: false
            }
        },
        computed: mapState('patients', {
            selectedPatient: state => state.selectedPatient,
            age:
                state => state.selectedPatient.birth_date ?
                    (Math.floor((new Date() - new Date(state.selectedPatient.birth_date).getTime()) / 3.15576e+10)) : ''
        }),
        methods: {
            getText(key) {
                return getTranslationString(key)
            },

        }
    }
</script>

<style scoped>

</style>
