import AdminDashboard from '../../pages/admin/Dashboard'

export default {
    path: '/admin',
        name: 'admin.dashboard',
    component: AdminDashboard,
    meta: {
        auth: {
            roles: 'ADMIN',
            redirect: { name: 'login' },
            forbiddenRedirect: '/403'
        }
    }
}
