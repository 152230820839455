<template>
    <v-layout row justify-end>

        <v-dialog v-model="dialog" persistent max-width="480">
            <template v-slot:activator="{ on }">
                <v-btn
                    v-on="on"
                    color="primary">
                    Fermer le traitement
                </v-btn>
            </template>
            <v-card>
                <v-form ref="closeTreatmentForm" lazy-validation>
                    <v-card-title class="headline">Fermer le traitement</v-card-title>
                    <v-card-text>
                        <v-container grid-list-md>
                            <v-layout wrap>
                                <v-flex xs12>
                                    <h4>Etes-vous sûr de vouloir fermer ce traitement ?</h4>
                                    <v-textarea v-model="remarks" label="Remarques"></v-textarea>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="secondary" @click="dialog = false">Annuler</v-btn>
                        <v-btn color="primary" @click="save">Oui, fermer</v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </v-layout>
</template>

<script>
    import {mapActions, mapState, mapMutations} from 'vuex'

    export default {
        name: "TreatmentCloseForm",
        data () {
            return {
                dialog: false,
                remarks: '',
            }
        },
        computed: {
            ...mapState('medical_cases', {
                selectedMedicalCase: state => state.selectedMedicalCase,
            }),
            ...mapState('treatments', {
                selectedTreatment: state => state.selectedTreatment,
            }),
        },
        methods: {
            save() {
                this.dialog = false;
                if (this.selectedTreatment && this.selectedMedicalCase) {
                    this.closeTreatment({remarks: this.remarks}).then(response => {
                        this.$router.push({name: 'patient.viewMedicalCase', params: { medical_case_id: this.selectedMedicalCase.id }});
                    }).catch(reason => {
                        //Stay here..
                    })
                }
            },
            ...mapActions('treatments', [
                'closeTreatment'
            ]),
        }
    }
</script>
