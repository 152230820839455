<template>
    <v-container>
        <v-stepper v-model="step" :class="mode == 'edit' ? '' : 'fill-height'">
            <v-stepper-header>
                <v-stepper-step :complete="step > 1" step="1" @click="mode == 'edit' ? step = 1 : {}">Informations du patient</v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :complete="step > 2" step="2" @click="mode == 'edit' ? step = 2 : {}">Informations de contact</v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :complete="step > 3" step="3" @click="mode == 'edit' ? step = 3 : {}">Médecins</v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :complete="step > 4" step="4" @click="mode == 'edit' ? step = 4 : {}">Cabinet</v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :complete="step > 5" step="5" @click="mode == 'edit' ? step = 5 : {}">Travail</v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :complete="step > 6" step="6" @click="mode == 'edit' ? step = 6 : {}">Activité sportive</v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
                <v-stepper-content step="1">
                    <v-form ref="patientForm" v-model="patientInfoValid" lazy-validation>

                        <v-layout>
                            <v-flex md10 offset-md1>
                                <h3 class="headline mb-4">Informations du patient</h3>

                                <v-select
                                    v-model="gender"
                                    :items="[{text: 'Homme', value: 'male'}, {text: 'Femme', value: 'female'}, {text: 'Indéfini', value: 'undefined'}]"
                                    label="Sexe"
                                    :rules="genderRules"
                                    required
                                ></v-select>
                                <v-text-field v-model="firstname" :rules="nameRules" label="Prénom"
                                              required></v-text-field>
                                <v-text-field v-model="lastname" :rules="nameRules" label="Nom" required></v-text-field>

                                <v-menu
                                    ref="showDatePicker"
                                    v-model="showDatePicker"
                                    :close-on-content-click="false"
                                    lazy
                                    transition="scale-transition"
                                    offset-y
                                    full-width
                                    max-width="290px"
                                    min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            v-model="computedBirthDateFormatted"
                                            label="Date de naissance"
                                            append-icon="event"
                                            readonly
                                            v-on="on"
                                            :rules="dateRules"
                                            required
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker ref="birthdayPicker"
                                                   v-model="birth_date"
                                                   :max="new Date().toISOString()"
                                                   no-title @input="showDatePicker = false"></v-date-picker>
                                </v-menu>

                            </v-flex>
                        </v-layout>
                        <v-layout row mt-2>
                            <v-flex md6 offset-md3 text-xs-right>
                                <v-btn :disabled="!patientInfoValid" color="success" @click="validate">Suivant
                                </v-btn>
                            </v-flex>
                        </v-layout>
                    </v-form>
                </v-stepper-content>

                <v-stepper-content step="2">
                    <v-form ref="contactForm" v-model="contactInfoValid" lazy-validation>
                        <v-layout>
                            <v-flex md10 offset-md1>
                                <h3 class="headline mb-4">Informations de contact pour {{ firstname }} {{ lastname
                                    }}</h3>

                                <v-text-field v-model="email" :rules="emailRules" label="Email"></v-text-field>
                                <v-text-field v-model="phone" label="Téléphone"></v-text-field>
                                <v-text-field v-model="address" label="Adresse"></v-text-field>
                                <v-text-field v-model="postcode" label="Code postal"></v-text-field>
                                <v-text-field v-model="city" label="Ville"></v-text-field>
                                <v-select
                                    :items="countryList"
                                    v-model="country" label="Pays"
                                    item-text="name"
                                    item-value="name"
                                ></v-select>

                            </v-flex>
                        </v-layout>
                        <v-layout row mt-2>
                            <v-flex md6 offset-md3>
                                <v-btn color="success" @click="previous">
                                    Précédent
                                </v-btn>

                                <v-btn :disabled="!contactInfoValid" color="success" @click="validateContact" class="right">
                                    Suivant
                                </v-btn>

                                <v-btn v-if="mode == 'create' && locked == 'null'" color="secondary" @click="skip" class="right">
                                    Passer
                                </v-btn>
                            </v-flex>
                        </v-layout>
                    </v-form>
                </v-stepper-content>

                <v-stepper-content step="3">

                    <v-form ref="doctorsForm" v-model="doctorsInfoValid" lazy-validation>

                        <v-layout>
                            <v-flex md10 offset-md1>
                                <div>
                                    <h3 class="headline mb-4">Médecins</h3>

                                    <v-text-field v-model="mainDoctorName" label="Médecin traitant" :rules="nameRules" required></v-text-field>
                                    <v-text-field v-model="physioName" label="Physiothérapeute"></v-text-field>

                                    <v-layout v-for="(doctor, index) in otherDoctors" :key="index" row wrap>
                                        <v-flex md3 pr-2>
                                            <v-select
                                                :ref="'typeSelect_' + index"
                                                :value="doctor.type"
                                                @change="(value) => { setOtherDoctorType({index: index, data: value}) }"
                                                :items="[{text: 'Cardiologue', value: 'CARDIO'}, {text: 'Autre médecin', value: 'OTHER'}]"
                                                label="Type"
                                                required
                                            ></v-select>
                                        </v-flex>
                                        <v-flex md4 v-if="doctor.type === 'OTHER'" pr-2>
                                            <v-text-field
                                                required
                                                :value="doctor.label"
                                                @change="(value) => { return setOtherDoctorLabel({index: index, data: value}) }"
                                                label="Domaine"></v-text-field>
                                        </v-flex>
                                        <v-flex md4>
                                            <v-text-field
                                                :value="doctor.name"
                                                @change="(value) => { return setOtherDoctorName({index: index, data: value}) }"
                                                label="Nom du médecin" append-outer-icon="clear" @click:append-outer="removeDoctor(index)"></v-text-field>
                                        </v-flex>

                                    </v-layout>
                                    <v-layout>
                                        <v-btn flat color="secondary" @click="addDoctor">Ajouter un médecin</v-btn>
                                    </v-layout>
                                </div>
                            </v-flex>
                        </v-layout>
                        <v-layout row mt-2>
                            <v-flex md6 offset-md3>
                                <v-btn color="success" @click="previous">
                                    Précédent
                                </v-btn>

                                <v-btn color="success" @click="validateDoctors" class="right">Suivant</v-btn>

                                <v-btn v-if="mode == 'create' && locked == 'null'" color="secondary" @click="skip" class="right">
                                    Passer
                                </v-btn>
                            </v-flex>
                        </v-layout>
                    </v-form>
                </v-stepper-content>

                <v-stepper-content step="4">
                    <v-form ref="firmForm" v-model="firmInfoValid" lazy-validation>
                        <v-layout>
                            <v-flex md6 offset-md3>
                                <h3 class="headline mb-4">Comment avez-vous choisi ce cabinet :</h3>
                                <v-select
                                    :menu-props="{ maxHeight: '100%' }"
                                    v-model="selectFirm"
                                    :items="[
                                    {text: getText('doctor'), value: 'doctor'},
                                    {text: getText('physio'), value: 'physio'},
                                    {text: getText('internet'), value: 'internet'},
                                    {text: getText('reception'), value: 'reception'},
                                    {text: getText('emergencies'), value: 'emergencies'},
                                    {text: getText('person_advise'), value: 'person_advise'},
                                    {text: getText('other'), value: 'other'}
                                    ]"
                                    label="Via"
                                ></v-select>
                                <v-text-field v-if="selectFirm === 'doctor' || selectFirm === 'physio' || selectFirm === 'other'"
                                              v-model="selectFirmOther" label="Nom: "></v-text-field>
                            </v-flex>
                        </v-layout>
                        <v-layout row mt-2>
                            <v-flex md6 offset-md3>
                                <v-btn color="success" @click="previous">
                                    Précédent
                                </v-btn>

                                <v-btn :disabled="!firmInfoValid" color="success" @click="validateFirm" class="right">Suivant</v-btn>

                                <v-btn v-if="mode == 'create' && locked == 'null'" color="secondary" @click="skip" class="right">
                                    Passer
                                </v-btn>
                            </v-flex>
                        </v-layout>
                    </v-form>
                </v-stepper-content>

                <v-stepper-content step="5">
                    <v-form ref="jobForm" v-model="jobInfoValid" lazy-validation>
                        <v-layout>
                            <v-flex md6 offset-md3>
                                <h3 class="headline mb-4">Quel est votre type de travail :</h3>
                                <v-select
                                    v-model="job"
                                    :items="[
                                    {text: getText('desk'), value: 'desk'},
                                    {text: getText('admin_manual'), value: 'admin_manual'},
                                    {text: getText('manual_light'), value: 'manual_light'},
                                    {text: getText('manual_heavy'), value: 'manual_heavy'},
                                    ]"
                                    label="Travail"
                                ></v-select>

                            </v-flex>
                        </v-layout>
                        <v-layout row mt-2>
                            <v-flex md6 offset-md3>
                                <v-btn color="success" @click="previous">
                                    Précédent
                                </v-btn>

                                <v-btn :disabled="!jobInfoValid" color="success" @click="validateJob" class="right">Suivant
                                </v-btn>

                                <v-btn v-if="mode == 'create' && locked == 'null'" color="secondary" @click="skip" class="right">
                                    Passer
                                </v-btn>
                            </v-flex>
                        </v-layout>
                    </v-form>
                </v-stepper-content>

                <v-stepper-content step="6">
                    <v-form ref="tegnerForm" v-model="tegnerInfoValid" lazy-validation>
                        <v-layout>
                            <v-flex md10 offset-md1>
                                <h3 class="headline mb-4">{{tegnerScore.score_question_text}}</h3>
                                <div class="custom-radio">
                                    <div class="radio mb-2"
                                         v-for="question_tegner in tegnerScore.score_question_answer"
                                         :key="question_tegner.id">
                                        <input type="radio" name="tegner" :value="question_tegner.value"
                                               v-model="tegner"
                                               :id="'answer'+question_tegner.id">
                                        <label class="v-label theme--light" :for="'answer'+question_tegner.id">{{question_tegner.text}}<span
                                            v-if="question_tegner.remarks"
                                            v-html="'<br>' + question_tegner.remarks"></span></label>
                                    </div>
                                </div>
                            </v-flex>
                        </v-layout>
                        <v-layout row mt-2>
                            <v-flex md6 offset-md3>
                                <v-btn color="success" @click="previous">
                                    Précédent
                                </v-btn>

                                <v-btn :disabled="!tegnerInfoValid" color="success" @click="validateTegner" class="right">Enregistrer
                                </v-btn>

                                <v-btn v-if="mode == 'create' && locked == 'null'" color="secondary" @click="skip" class="right">
                                    Passer
                                </v-btn>
                            </v-flex>
                        </v-layout>
                    </v-form>
                </v-stepper-content>

            </v-stepper-items>
        </v-stepper>
        <v-layout justify-center>
            <v-dialog v-model="dialog" persistent max-width="480">
                <v-card>
                    <v-card-title class="headline">Nouveau Patient</v-card-title>
                    <v-card-text>Voulez-vous vérouiller la tablette et activer la saisie patient ?</v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="secondary" text @click="continueAsSecretary">Non, continuer</v-btn>
                        <v-btn color="primary" text @click="continueAsPatient">Oui, verrouiller</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>
    </v-container>

</template>

<script>
    import {mapActions, mapState, mapMutations} from 'vuex'
    import {getTranslationString} from "../../helper"
    import countries from "../../countries"

    export default {
        name: "PatientForm",
        props: {
            mode: {
                type: String,
                default: 'create'
            }
        },
        data: () => ({
            showDatePicker: false,
            dialog: false,
            patientInfoValid: false,
            contactInfoValid: false,
            doctorsInfoValid: false,
            firmInfoValid: false,
            jobInfoValid: false,
            tegnerInfoValid: false,
            step: 1,
            nameRules: [
                v => !!v || 'Le nom est requis'
            ],
            emailRules: [
                v => (!v || (/.+@.+\..+/.test(v))) || 'E-mail must be valid'
            ],
            dateRules: [
                v => !!v || 'La date de naissance est requise'
            ],
            genderRules: [
                v => !!v || 'Le genre est requis',
            ]
        }),
        computed: {
            countryList() {
                return countries
            },
            ...mapState({
                locked: state => state.locked
            }),
            ...mapState('patients', {
                selectedPatientId: state => state.selectedPatient.id,
                tegnerScore: state => state.tegnerScore
            }),
            computedBirthDateFormatted: {
                get() {
                    return this.birth_date ? this.$options.filters.formatedDate(this.birth_date) : ''
                },
                set(value) {

                }
            },
            gender: {
                get() {
                    return this.$store.state.patients.form.gender
                },
                set(value) {
                    this.setGender(value)
                }
            },
            firstname: {
                get() {
                    return this.$store.state.patients.form.firstname
                },
                set(value) {
                    this.setFirstname(value)
                }
            },
            lastname: {
                get() {
                    return this.$store.state.patients.form.lastname
                },
                set(value) {
                    this.setLastname(value)
                }
            },
            birth_date: {
                get() {
                    return this.$store.state.patients.form.birth_date
                },
                set(value) {
                    this.setBirthDate(value)
                }
            },
            email: {
                get() {
                    return this.$store.state.patients.contactForm.email
                },
                set(value) {
                    this.setEmail(value)
                }
            },
            phone: {
                get() {
                    return this.$store.state.patients.contactForm.phone
                },
                set(value) {
                    this.setPhone(value)
                }
            },
            address: {
                get() {
                    return this.$store.state.patients.contactForm.address
                },
                set(value) {
                    this.setAddress(value)
                }
            },
            postcode: {
                get() {
                    return this.$store.state.patients.contactForm.postcode
                },
                set(value) {
                    this.setPostcode(value)
                }
            },
            city: {
                get() {
                    return this.$store.state.patients.contactForm.city
                },
                set(value) {
                    this.setCity(value)
                }
            },
            country: {
                get() {
                    return this.$store.state.patients.contactForm.country
                },
                set(value) {
                    this.setCountry(value)
                }
            },
            mainDoctorName: {
                get() {
                    return this.$store.state.patients.doctorsForm.main.name
                },
                set(value) {
                    this.setMainDoctorName(value)
                }
            },
            physioName: {
                get() {
                    return this.$store.state.patients.doctorsForm.physio.name
                },
                set(value) {
                    this.setPhysioName(value)
                }
            },
            otherDoctors: {
                get() {
                    return this.$store.state.patients.doctorsForm.others
                }
            },
            selectFirm: {
                get() {
                    return this.$store.state.patients.firmForm.firm_select
                },
                set(value) {
                    this.setSelectFirm(value)
                }
            },
            selectFirmOther: {
                get() {
                    return this.$store.state.patients.firmForm.firm_select_other
                },
                set(value) {
                    this.setSelectFirmOther(value)
                }
            },
            job: {
                get() {
                    return this.$store.state.patients.jobForm.job
                },
                set(value) {
                    this.setJob(value)
                }
            },
            tegner: {
                get() {
                    return this.$store.state.patients.tegnerForm.tegner
                },
                set(value) {
                    this.setTegner(value)
                }
            },
        },
        mounted() {
            this.getTegner()

            if (this.mode === 'create') {
                this.birth_date = null
                this.$refs.patientForm.reset()
                this.$refs.contactForm.reset()
                this.$refs.doctorsForm.reset()
                this.$refs.firmForm.reset()
                this.$refs.jobForm.reset()
                this.$refs.tegnerForm.reset()
            } else {
                this.loadPatientForm()
            }
        },
        methods: {
            showLabel(index) {
                if (this.$refs['typeSelect_' + index]) {
                    return this.$refs['typeSelect_' + index].value === "OTHER"
                }
                return false
            },
            previous() {
                this.step = this.step -1
            },
            validate() {
                if (this.$refs.patientForm.validate()) {
                    this.submit()
                }
            },
            validateContact() {
                if (this.$refs.contactForm.validate()) {
                    this.submitContactInfos()
                }
            },
            validateDoctors() {
                if (this.$refs.doctorsForm.validate()) {
                    this.submitDoctorsInfos()
                }
            },
            validateFirm() {
                if (this.$refs.firmForm.validate()) {
                    this.submitFirmInfos()
                }
            },
            validateJob() {
                if (this.$refs.jobForm.validate()) {
                    this.submitJobInfos()
                }
            },
            validateTegner() {
                if (this.$refs.tegnerForm.validate()) {
                    this.submitTegnerInfos()
                }
            },
            submitContactInfos() {
                this.updateContactInfos().then(response => {
                    this.step = 3
                }).catch(reason => {
                    //Stay here..
                })
            },
            submitDoctorsInfos() {
                this.updateDoctorsInfos().then(response => {
                    this.step = 4
                }).catch(reason => {
                    //Stay here..
                })

            },
            submitFirmInfos() {
                this.updateFirmInfos().then(response => {
                    this.step = 5
                }).catch(reason => {
                    //Stay here..
                })
            },
            submitJobInfos() {
                this.updateJobInfos().then(response => {
                    this.step = 6
                }).catch(reason => {
                    //Stay here..
                })
            },
            submitTegnerInfos() {
                this.updateTegnerInfos().then(response => {
                    this.$router.replace({name: 'patient.view', params: {id: this.selectedPatientId}})
                }).catch(reason => {
                    //Stay here..
                })
            },
            continueAsSecretary() {
                this.dialog = false
                this.step = 2
            },
            continueAsPatient() {
                this.dialog = false

                this.updateLockedState("true")

                this.step = 2
            },
            submit() {
                if (this.mode == 'create') {
                    this.createPatient().then(response => {
                        if (response.data.state === 'new') {
                            this.setSelectedPatientId(response.data.patient)
                            this.dialog = true
                        } else {
                            //Move back to the user list
                            this.$router.replace({name: 'patient.view', params: {id: response.data.patient}})
                        }

                    }).catch(reason => {
                        //Stay here..
                    })
                } else {
                    this.updatePatient().then(response => {
                        this.step = 2
                    }).catch(reason => {
                        //Stay here..
                    })
                }

            },
            skip() {
                this.$router.replace({name: 'patient.view', params: {id: this.selectedPatientId}})
            },
            getText(key) {
                return getTranslationString(key)
            },
            ...mapMutations(['updateLockedState']),
            ...mapMutations('patients', {
                setGender: 'setFormGender',
                setFirstname: 'setFormFirstname',
                setLastname: 'setFormLastname',
                setBirthDate: 'setFormBirthDate',
                setEmail: 'setFormEmail',
                setPhone: 'setFormPhone',
                setAddress: 'setFormAddress',
                setPostcode: 'setFormPostcode',
                setCity: 'setFormCity',
                setCountry: 'setFormCountry',
                setMainDoctorName: 'setFormMainDoctorName',
                setPhysioName: 'setFormPhysioName',
                setSelectedPatientId: 'setSelectedPatientId',
                initPatientForms: 'initPatientForms',
                setOtherDoctorType: 'setFormOtherDoctorType',
                setOtherDoctorName: 'setFormOtherDoctorName',
                setOtherDoctorLabel: 'setFormOtherDoctorLabel',
                addDoctor: 'addNewEmptyDoctor',
                setSelectFirm: 'setFormSelectFirm',
                setSelectFirmOther: 'setFormSelectFirmOther',
                setJob: 'setFormJob',
                setTegner: 'setFormTegner',
                removeDoctor: 'removeDoctorAtIndex',
            }),
            ...mapActions('patients', ['getTegner', 'createPatient', 'updatePatient', 'updateContactInfos', 'updateDoctorsInfos', 'updateFirmInfos', 'updateJobInfos', 'updateTegnerInfos', 'loadPatientForm'])
        },
        watch: {
            showDatePicker (val) {
                this.birth_date ? val : (val && setTimeout(() => (this.$refs.birthdayPicker.activePicker = 'YEAR')))
            }
        },
    }
</script>

<style scoped>

</style>
