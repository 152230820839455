import treatments from '../../api/treatments'

const success = {
    CREATE_TREATMENT_SUCCESS: {
        title: "SUCCESS",
        content: "Traitement créé avec succès"
    },
    UPDATE_TREATMENT_SUCCESS: {
        title: "SUCCESS",
        content: "Traitement mis à jour"
    },
    CLOSE_TREATMENT_SUCCESS: {
        title: "SUCCESS",
        content: "Traitement fermé avec succès"
    },
}
const errors = {
    CREATE_TREATMENT_ERROR: 'Erreur lors de la création du traitement, merci de réessayer',
    UPDATE_TREATMENT_ERROR: 'Erreur lors de la mise à jour du traitement, merci de réessayer',
    CLOSE_TREATMENT_ERROR: 'Erreur lors de la fermeture du traitement, merci de réessayer',
    GET_TREATMENT_LOADING_ERROR: 'Erreur lors du chargement du traitement, merci de réessayer',
}

// initial state
const state = {
    form: {
        id: null,
        type: null,
        description: null
    },
    selectedTreatment: {
        id: null,
        type: null,
        description: null,
        follow_ups: {
            follow_up_definition: {

            },
            follow_up_scores: {
                score: null
            }
        },
        medical_case: {
            diagnostic: {
                type: null
            }
        },
        scores_definition: {},
    },
    showForm: false
}

// getters
const getters = {}

// actions
const actions = {

    createTreatment({state, commit, rootState}) {
        return new Promise((resolve, reject) => {
            treatments.add(rootState.patients.selectedPatient.id, rootState.medical_cases.selectedMedicalCase.id, state.form, rootState.axios).then(result => {
                commit('notifications/showSuccess', {
                    title: success.CREATE_TREATMENT_SUCCESS.title, content: success.CREATE_TREATMENT_SUCCESS.content
                }, {root: true})
                resolve(result)
            }).catch(reason => {
                commit('notifications/showError', {
                    title: errors.CREATE_TREATMENT_ERROR, content: reason
                }, {root: true})
                reject(reason)
            })
        })
    },
    updateTreatment({state, commit, rootState}) {
        return new Promise((resolve, reject) => {
            treatments.update(rootState.patients.selectedPatient.id, rootState.medical_cases.selectedMedicalCase.id, rootState.treatments.selectedTreatment.id, state.form, rootState.axios).then(result => {
                commit('notifications/showSuccess', {
                    title: success.UPDATE_TREATMENT_SUCCESS.title, content: success.UPDATE_TREATMENT_SUCCESS.content
                }, {root: true})
                resolve(result)
            }).catch(reason => {
                commit('notifications/showError', {
                    title: errors.UPDATE_TREATMENT_ERROR, content: reason
                }, {root: true})
                reject(reason)
            })
        })
    },
    getTreatmentInfo({state, commit, rootState}) {
        return new Promise((resolve, reject) => {
            treatments.get(rootState.patients.selectedPatient.id, rootState.medical_cases.selectedMedicalCase.id, rootState.treatments.selectedTreatment.id, rootState.axios).then(result => {
                commit('setSelectedTreatment', result.data.treatment)
                resolve(result)
            }).catch(reason => {
                commit('notifications/showError', {
                    title: errors.GET_TREATMENT_LOADING_ERROR, content: reason
                }, {root: true})
                reject(reason)
            })
        })
    },
    closeTreatment({state, commit, rootState}, payload) {
        return new Promise((resolve, reject) => {
            treatments.close(rootState.patients.selectedPatient.id, rootState.medical_cases.selectedMedicalCase.id, rootState.treatments.selectedTreatment.id, payload, rootState.axios).then(result => {
                commit('notifications/showSuccess', {
                    title: success.CLOSE_TREATMENT_SUCCESS.title,
                    content: success.CLOSE_TREATMENT_SUCCESS.content
                }, {root: true})
                resolve(result)
            }).catch(reason => {
                commit('notifications/showError', {
                    title: errors.CLOSE_TREATMENT_ERROR, content: reason
                }, {root: true})
                reject(reason)
            })
        })
    }
}

// mutations
const mutations = {
    setFormId(state, value) {
        state.form.id = value
    },
    setFormType(state, value) {
        state.form.type = value
    },
    setFormDescription(state, value) {
        state.form.description = value
    },
    initForm (state, value) {
        if (value != null) {
            state.form.id = value.id
            state.form.type = value.type
            state.form.description = value.description
        } else {
            state.form.id = null
            state.form.type = null
            state.form.description = null
        }
    },
    setShowForm(state, value) {
        state.showForm = value
    },
    setSelectedTreatmentId (state, value) {
        state.selectedTreatment.id = value
    },
    setSelectedTreatment (state, value) {
        state.selectedTreatment = value
        if (value.treatment) {
            state.form = { ...value.treatment}
        } else {
            state.form = {
                type: null,
                description: null
            }
        }
    },
    resetForm (state) {
        state.form.type = null
        state.form.description = null
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
