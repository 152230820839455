<template>
    <v-card class="patient fill-height">
        <v-card-title primary-title>
            <v-icon v-if="locked == 'null'" @click="back" class="icon-back">
                keyboard_arrow_left
            </v-icon>
            <div>
                <div class="headline">{{ selectedPatient.lastname + " " + selectedPatient.firstname }}</div>
                <div>{{ this.getText(selectedPatient.gender) }} - {{ selectedPatient.birth_date | shortDate }} ({{
                    this.age }} ans)
                </div>
            </div>
            <!--<v-spacer></v-spacer>-->
            <v-spacer></v-spacer>
            <patient-general-infos-modal v-if="$route.name === 'patient.view' || $route.name === 'patient.viewMedicalCase' || $route.name === 'patient.viewTreatment'"></patient-general-infos-modal>
            <!--<v-btn
                v-if="$route.name === 'patient.view' || $route.name === 'patient.viewMedicalCase' || $route.name === 'patient.viewTreatment'"
                flat @click="dialog_patient = true" color="primary">
                <v-icon>mdi-clipboard-account</v-icon> Info patient
            </v-btn>-->

            <v-btn v-if="$route.name === 'patient.view'" flat @click="dialog = true">
                <v-icon>edit</v-icon>
            </v-btn>
            <treatment-close-form v-if="$route.name === 'patient.viewTreatment' && selectedTreatment.open"></treatment-close-form>
            <case-close-form v-if="$route.name === 'patient.viewMedicalCase' && selectedMedicalCase.open"></case-close-form>
            <!--<v-btn v-if="$route.name === 'patient.viewTreatment'" @click="dialog_treatment = true" color="primary">Fermer
                le traitement
            </v-btn>-->
        </v-card-title>

        <v-alert  v-if="$route.name === 'patient.viewMedicalCase' && selectedMedicalCase.open == false" class="mb-0"
                  :value="true"
                  color="warning">
            <v-tooltip v-if="selectedMedicalCase.remarks" bottom>
                <template v-slot:activator="{ on }">
                    <v-icon dark v-on="on">info</v-icon>
                </template>
                <span>{{ selectedMedicalCase.remarks}}</span>
            </v-tooltip>
            Cas fermé le : {{ selectedMedicalCase.updated_at | shortDate }}
        </v-alert>

        <v-alert  v-if="$route.name === 'patient.viewTreatment' && selectedTreatment.open == false" class="mb-0"
                  :value="true"
                  color="warning">
            <v-tooltip v-if="selectedTreatment.remarks" bottom>
                <template v-slot:activator="{ on }">
                    <v-icon dark v-on="on">info</v-icon>
                </template>
                <span>{{ selectedTreatment.remarks}}</span>
            </v-tooltip>
            Traitement fermé le : {{ selectedTreatment.updated_at | shortDate }}
        </v-alert>

        <v-divider></v-divider>

        <router-view></router-view>
        <v-dialog v-model="dialog" persistent max-width="480">
            <v-card>
                <v-card-title class="headline">Modifier le patient</v-card-title>
                <v-card-text>Voulez-vous vérouiller la tablette et activer la saisie patient ?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" text @click="editPatient(false)">Non, continuer</v-btn>
                    <v-btn color="primary" text @click="editPatient(true)">Oui, verrouiller</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
    import {mapActions, mapState, mapMutations} from 'vuex'
    import {getTranslationString} from "../../helper";
    import PatientGeneralInfosModal from "./modal/PatientGeneralInfosModal";
    import TreatmentCloseForm from "./modal/TreatmentCloseForm";
    import CaseCloseForm from "./modal/CaseCloseForm";

    export default {
        name: "UserView",
        components: {
            'patient-general-infos-modal' : PatientGeneralInfosModal,
            'treatment-close-form' : TreatmentCloseForm,
            'case-close-form' : CaseCloseForm
        },
        computed: {
            ...mapState({
                locked: state => state.locked
            }),
            ...mapState(
                'patients',
                {
                    selectedPatient: state => state.selectedPatient,
                    age:
                        state => state.selectedPatient.birth_date ?
                            (Math.floor((new Date() - new Date(state.selectedPatient.birth_date).getTime()) / 3.15576e+10))
                            : ''
                }
            ),
            ...mapState('medical_cases', {
                selectedMedicalCase: state => state.selectedMedicalCase,
            }),
            ...mapState('treatments', {
                selectedTreatment: state => state.selectedTreatment,
            })
        },
        data() {
            return {
                dialog: false,
                dialog_case: false,
                dialog_treatment: false,
                dialog_patient: false,
            }
        },
        created() {
            this.setSelectedPatientId(this.$route.params.id)
            this.getPatientInfo()
        },
        methods: {
            back() {
                this.$router.back()
            },
            ...mapActions('patients', [
                'getPatientInfo'
            ]),
            ...mapMutations('patients', [
                'setSelectedPatientId'
            ]),
            getText(key) {
                return getTranslationString(key)
            },
            editPatient(locked) {
                this.dialog = false;
                this.$router.push({
                    name: 'patient.edit', params: {
                        locked: locked
                    }
                })
            },
        }
    }
</script>

