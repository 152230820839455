<template>

    <v-layout>
        <v-flex>
            <v-layout mb-2>
                <div class="subtitle mb-0">Cas en cours</div>
                <v-spacer></v-spacer>
                <v-btn @click="dialog = true" color="secondary">
                    <v-icon>add</v-icon>
                    Nouveau cas
                </v-btn>
            </v-layout>

            <v-container grid-list-sm pa-0>
                <v-layout row v-if="open && open.length > 0">
                    <v-flex xs12>
                        <v-list three-line>
                            <template v-for="(cas, index) in open">
                                <v-list-tile :key="'case-' + cas.id"
                                             :to="{name: 'patient.viewMedicalCase', params: { medical_case_id: cas.id }}">
                                    <v-list-tile-content>
                                        <v-list-tile-title><span class="title">{{ cas.name }}</span>
                                        </v-list-tile-title>
                                        <v-list-tile-sub-title
                                            v-if="cas.medical_info != null && cas.medical_info.articulation">
                                            <span class="subheading">{{getText(cas.medical_info.articulation) }}</span>
                                        </v-list-tile-sub-title>
                                        <v-list-tile-sub-title>
                                            ouvert le {{ cas.created_at | shortDate }}
                                        </v-list-tile-sub-title>
                                    </v-list-tile-content>
                                    <v-list-tile-action>
                                        <v-btn flat
                                               :to="{name: 'patient.viewMedicalCase', params: { medical_case_id: cas.id }}">
                                            <v-icon>arrow_forward</v-icon>
                                        </v-btn>
                                    </v-list-tile-action>
                                </v-list-tile>
                                <v-divider
                                    v-if="index + 1 < open.length"
                                    :key="'divider-' + index"
                                ></v-divider>
                            </template>
                        </v-list>
                    </v-flex>
                </v-layout>
                <v-layout v-else mb-5>
                    <v-spacer></v-spacer>
                    <div>Aucun cas en cours</div>
                    <v-spacer></v-spacer>
                </v-layout>

            </v-container>

            <v-layout>
                <v-flex>
                    <v-list v-if="history.length > 0" three-line>
                        <h3>Anciens cas</h3>
                        <template v-for="(oldMedicalCase, index) in history">
                            <v-list-tile :key="'old-case-' + oldMedicalCase.id" class="text--disabled"
                                         :to="{name: 'patient.viewMedicalCase', params: { medical_case_id: oldMedicalCase.id }}">
                                <v-list-tile-content>
                                    <v-list-tile-title><span class="title">{{ oldMedicalCase.name }}</span>
                                    </v-list-tile-title>
                                    <v-list-tile-sub-title
                                        v-if="oldMedicalCase.medical_info != null && oldMedicalCase.medical_info.articulation">
                                        <span class="subheading">{{getText(oldMedicalCase.medical_info.articulation) }}</span>
                                    </v-list-tile-sub-title>
                                    <v-list-tile-sub-title>
                                        fermé le {{ oldMedicalCase.updated_at | shortDate }}
                                    </v-list-tile-sub-title>
                                </v-list-tile-content>
                                <v-list-tile-action>
                                    <v-btn flat disabled>
                                        <v-icon>arrow_forward</v-icon>
                                    </v-btn>
                                </v-list-tile-action>
                            </v-list-tile>
                            <v-divider
                                v-if="index + 1 < history.length"
                                :key="'old-divider-' + index"
                            ></v-divider>
                        </template>
                    </v-list>
                </v-flex>
            </v-layout>

            <v-dialog max-width="480" persistent v-model="dialog">
                <v-card>
                    <v-card-title class="headline">Nouveau cas</v-card-title>
                    <v-form ref="newMedicalCaseForm" lazy-validation>
                        <v-card-text>
                            Saisir un nom pour le nouveau cas:
                            <v-text-field
                                v-model="newMedicalCaseName"
                                label="Nom"
                                :rules="notEmptyRules"
                                required
                            ></v-text-field>
                        </v-card-text>
                    </v-form>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="dialog = false, newMedicalCaseName = null">Annuler</v-btn>
                        <v-btn @click="createNewMedicalCase(false)" color="secondary" text>Continuer</v-btn>
                        <v-btn @click="createNewMedicalCase(true)" color="primary" text>Continuer et verrouiller</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-flex>
    </v-layout>

</template>

<script>
    import {mapActions, mapState} from 'vuex'
    import {getTranslationString} from "../../../helper";

    export default {
        name: "MedicalCaseListView",
        computed: mapState('medical_cases', {
            open: state => state.open,
            history: state => state.history,
        }),
        data: () => ({
            dialog: false,
            newMedicalCaseName: "",
            notEmptyRules: [
                v => !!v || 'Le nom est requis'
            ],
        }),
        mounted() {
            this.getMedicalCases()
        },
        methods: {
            ...mapActions('medical_cases', [
                'getMedicalCases',
                'createMedicalCase'
            ]),
            createNewMedicalCase(locked) {
                if (this.$refs.newMedicalCaseForm.validate()) {
                    this.createMedicalCase({name: this.newMedicalCaseName}).then(response => {
                        this.$router.push({
                            name: 'patient.addMedicalCase',
                            params: {medical_case_id: response.data.medical_case.id, locked: locked}
                        })

                    }).catch(reason => {
                        //Stay here..
                    })
                }
            },
            getText(key) {
                return getTranslationString(key)
            },
            openCase(caseId) {
                this.$router.push({name: 'patient.viewMedicalCase', params: {medical_case_id: caseId}});
            }
        }
    }
</script>

